import * as bitcoin from "bitcoinjs-lib";
import getConfig from "./getOverload";

export const environment = {
  version: "v0.41.07.07-beta",
  production: true,
  apiUrl: "https://beta-api.lmnl.app/api",
  adminPanelUrl: "https://nowhere.lmnl.app",
  disableTennetBuildFts: getConfig("disableTennetBuildFts", false),
  auth0ClientKey: getConfig(
    "auth0ClientKey",
    "Oca9DFKGIPxnahZljlhAqJrL4DTozOcm"
  ),
  auth0Domain: getConfig("auth0Domain", "lmnlhq.us.auth0.com"),
  auth0SupportUrl: getConfig("auth0SupportUrl", "mailto:support@lmnl.app"),
  chainId: 1,
  chain: "mainnet",
  btcNetwork: bitcoin.networks.bitcoin,
  buildType: getConfig("buildType", "vaults"),
  buildName: getConfig("buildName", "Liminal"),
  buildFor: getConfig("buildFor", "standard"),
  xpubConst: "0488b21e",
  env: "prod",
  writekey_segment: getConfig(
    "writekey_segment",
    "mdbec4giYsKnEJOeN7x2WzxfixDGU6yg"
  ),
  configcat: "ktLaCCG1IEGP7oqBCJTT1A/zOoWviK22UWRtYhsz3T2IA",
  mmi: {
    apiUrl: "https://api.lmnl.app",
    environment: "liminal-prod",
  },
  expressUrl: " https://lmnl.app/go-to-liminal-express-stack",
  walletConnect: {
    PROJECT_ID: "06b1014c4bf1ebe09985d21d7cb8ba2e",
    metadata: {
      name: "Liminal Vaults",
      description: "Liminal vaults client as a wallet",
      url: "https://www.lmnl.app/",
      icons: [],
    },
  },
  utxo_config: {
    BTC: {
      network: bitcoin.networks.bitcoin,
      coinPath: "0'",
      coin: "btc",
      decimalPlaces: 8,
    },
  },
  safe_relay_url: "https://safe-relay.gnosis.io/api/v1",
  evm_config: {
    ETH: {
      infura_url: "https://eth-fullnode.lmnl.app",
      gnosis_api: "https://safe-client.safe.global/v1/chains/1",
      web3http: "https://eth-fullnode.lmnl.app",
      nativecoin: "ETH",
      chainId: 1,
    },
    TELOS: {
      infura_url: "https://mainnet.telos.net/evm",
      gnosis_api: "https://gateway.safe.telos.net/v1/chains/40",
      web3http: "https://mainnet.telos.net/evm",
      nativecoin: "TLOS",
      chainId: 40,
    },
    BSC: {
      infura_url: "https://bsc-dataseed1.binance.org/",
      gnosis_api: "https://safe-client.safe.global/v1/chains/56",
      web3http: "https://bsc-dataseed1.binance.org/",
      nativecoin: "BNB",
      chainId: 56,
    },
    POLYGON: {
      infura_url: "https://polygon-rpc.com/",
      gnosis_api: "https://safe-client.safe.global/v1/chains/137",
      web3http: "https://polygon-rpc.com/",
      nativecoin: "MATIC",
      chainId: 137,
    },
    XDAI: {
      infura_url: "https://rpc.gnosischain.com",
      web3http: "https://rpc.gnosischain.com",
      nativecoin: "xDai",
      gnosis_api: "https://safe-client.safe.global/v1/chains/100",
      chainId: 100,
    },
    EWC: {
      infura_url: "https://rpc.energyweb.org",
      web3http: "https://rpc.energyweb.org",
      nativecoin: "EWT",
      gnosis_api: "https://safe-client.safe.global/v1/chains/246",
      chainId: 246,
    },
    ARBITRUM: {
      infura_url: "https://arb1.arbitrum.io/rpc",
      web3http: "https://arb1.arbitrum.io/rpc",
      nativecoin: "AETH",
      gnosis_api: "https://safe-client.safe.global/v1/chains/42161",
      chainId: 42161,
    },
    AVALANCHE: {
      infura_url:
        "https://rpc.ankr.com/avalanche/4b2763e373412067b180bf777ad9c87d5c6c7f1bfd6bd920db9a1cbf885263e2",
      web3http:
        "https://rpc.ankr.com/avalanche/4b2763e373412067b180bf777ad9c87d5c6c7f1bfd6bd920db9a1cbf885263e2",
      nativecoin: "AVAX",
      gnosis_api: "https://safe-client.safe.global/v1/chains/43114",
      chainId: 43114,
    },
    OPTIMISM: {
      infura_url: "https://mainnet.optimism.io",
      web3http: "https://mainnet.optimism.io",
      nativecoin: "OETH",
      gnosis_api: "https://safe-client.safe.global/v1/chains/10",
      chainId: 10,
    },
    FANTOM: {
      gnosis_api: "https://safe.fantom.network/v1/chains/250",
      infura_url:
        "https://ancient-little-waterfall.fantom.quiknode.pro/41ea138c7216e7ebc4947fda5e2c59fd26247497/",
      nativecoin: "FTM",
      web3http:
        "https://ancient-little-waterfall.fantom.quiknode.pro/41ea138c7216e7ebc4947fda5e2c59fd26247497/",
      chainId: 250,
    },
    ETHW: {
      infura_url: "",
      gnosis_api: "",
      web3http: "https://mainnet.ethereumpow.org",
      nativecoin: "ETHW",
      chainId: 10001,
    },
    KLAY: {
      web3http: "https://public-en-cypress.klaytn.net/",
      dataDecoderUrl: "",
      decimalPlaces: 18,
      explorer_link: "https://scope.klaytn.com/tx/",
      explorer_link_address: "https://scope.klaytn.com/account/address/",
      path: "m/44'/60'/0'/0/0",
      pathMPC: "m/44/60/0",
      nativecoin: "KLAY",
      gnosis_api: "",
      iseip1559available: false,
      chainId: 8217,
    },
    XINFIN: {
      web3http: "https://rpc.xinfin.network",
      dataDecoderUrl: "https://yplusvault.com:8001/v1/transactions/multisig_",
      decimalPlaces: 18,
      explorer_link: "https://explorer.xinfin.network/txs/",
      explorer_link_address: "https://explorer.xinfin.network/address/",
      path: "m/44'/60'/0'/0/0",
      nativecoin: "XDC",
      gnosis_api: "https://yplusvault.com:8000/api/v1/",
      chainId: 50,
      networkChain: 50,
    },
    PLAYA3ULL: {
      web3http: "https://api.mainnet.playa3ull.games/",
      decimalPlaces: 18,
      explorer_link: "https://explorer.playa3ull.games/blockchain/playa3ull/",
      explorer_link_address: "https://explorer.playa3ull.games/blockchain/playa3ull/address/",
      path: "m/44'/60'/0'/0/0",
      nativecoin: "3ULL",
      chainId: 3011,
      iseip1559available: true,
      gnosis_api: "",
      dataDecoderUrl: ""
    },
    BAHAMUT: {
      web3http: "https://rpc1.bahamut.io/",
      decimalPlaces: 18,
      explorer_link: "https://ftnscan.com/tx/",
      explorer_link_address: "https://ftnscan.com/address/",
      path: "m/44'/60'/0'/0/0",
      nativecoin: "FTN",
      chainId: 5165,
      iseip1559available: true,
      gnosis_api: "",
      dataDecoderUrl: "",
    },
  },
  tron_config: {
    TRON: {
      decimalPlaces: 6,
      explorer_link: "https://tronscan.org/#/transaction/",
      explorer_link_address: "https://tronscan.org/#/address/",
      path: "m/44'/144'/0'/0/0",
      nativecoin: "TRX",
    },
  },
  dot_config: {
    DOT: {
      decimalPlaces: 10,
      explorer_link: "https://westend.subscan.io/extrinsic/",
      explorer_link_address: "https://westend.subscan.io/account/",
      path: "44'/354'/0'/0'/0'",
      nativecoin: "DOT",
      parentchain: "DOT",
      LEDGER_DEFAULT_ACCOUNT: 0x80000000,
      LEDGER_DEFAULT_CHANGE: 0x80000000,
      LEDGER_DEFAULT_INDEX: 0x80000000,
    },
  },
  sol_config: {
    SOL: {
      decimalPlaces: 9,
      explorer_link: "https://solscan.io/tx/",
      explorer_link_address: "https://solscan.io/account/",
      path: "44'/501'/0'",
      nativecoin: "SOL",
      parentchain: "SOL",
      network: "mainnet-beta",
    },
  },
  xlm_config: {
    XLM: {
      explorer_link: "https://stellarchain.io/transactions/",
      explorer_link_address: "https://stellarchain.io/accounts/",
      path: "44'/148'/0'",
      parentchain: "XLM",
      nativecoin: "XLM",
      network: "public",
    },
  },
  xrp_config: {
    XRP: {
      infura_url:
        "https://misty-withered-darkness.bsc.quiknode.pro/71c2a380a6b77b3199ba99278bc05145a286e750/",
      gnosis_api: "https://safe-client.safe.global/v1/chains/56",
      web3http:
        "https://misty-withered-darkness.bsc.quiknode.pro/71c2a380a6b77b3199ba99278bc05145a286e750/",
      nativecoin: "XRP",
      PUBLIC_SERVER: "https://s1.ripple.com:51234/",
      path: "44'/144'/0'/0/0",
    },
  },
  cosmos_config: {
    "COSMOSHUB-4": {
      decimalPlaces: 6,
      explorer_link: "https://atomscan.com/transactions/",
      explorer_link_address: "https://atomscan.com/accounts/",
      path: "m/44'/118'/0'/0/0",
      nativecoin: "ATOM",
      denom: "UATOM",
      prefix: "cosmos",
      parentchain: "COSMOS",
    },
  },
  DEFI_LIB_COMPOUND_ADDRESS: {
    DAI: "0x5592EC0cfb4dbc12D3aB100b257153436a1f0FEa",
    BAT: "0xbf7a7169562078c96f0ec1a8afd6ae50f12e5a99",
    ETH: "0xc778417E063141139Fce010982780140Aa0cD5Ab",
    REP: "0x6e894660985207feb7cf89Faf048998c71E8EE89",
    USDC: "0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b",
    USDT: "0xD9BA894E0097f8cC2BBc9D24D308b98e36dc6D02",
    WBTC: "0x577D296678535e4903D59A4C929B718e1D575e0A",
    ZRX: "0xddea378A6dDC8AfeC82C36E9b0078826bf9e68B6",
    cETH: "0xd6801a1dffcd0a410336ef88def4320d6df1883e",
  },
  staking_chain_list: [
    {
      chain: "ETH",
      assets: ["MATIC"],
    },
  ],
  MPC_Protocol_Support: {
    CARDANO: {
      signerType: ["MPC"],
    },
    ETH: {
      signerType: ["MULTI SIG", "MPC"],
    },
    BSC: {
      signerType: ["MULTI SIG", "MPC"],
    },
    POLYGON: {
      signerType: ["MULTI SIG", "MPC"],
    },
    XDAI: {
      signerType: ["MULTI SIG", "MPC"],
    },
    EWC: {
      signerType: ["MULTI SIG", "MPC"],
    },
    ARBITRUM: {
      signerType: ["MULTI SIG", "MPC"],
    },
    AVALANCHE: {
      signerType: ["MULTI SIG", "MPC"],
    },
    OPTIMISM: {
      signerType: ["MULTI SIG", "MPC"],
    },
    FANTOM: {
      signerType: ["MULTI SIG", "MPC"],
    },
    ETHW: {
      signerType: ["MULTI SIG", "MPC"],
    },
    KLAY: {
      signerType: ["MULTI SIG", "MPC"],
    },
    XINFIN: {
      signerType: ["MULTI SIG", "MPC"],
    },
    XLM: {
      signerType: ["MULTI SIG", "MPC"],
    },
    XRP: {
      signerType: ["MULTI SIG", "MPC"],
    },
    BNB: {
      signerType: ["MPC"],
    },
    TRON: {
      signerType: ["MULTI SIG", "MPC"],
    },
    SOL: {
      signerType: ["MPC"],
    },
    NEAR: {
      signerType: ["MPC"],
    },
    ZKEVM: {
      signerType: ["MPC"],
    },
    BTC: {
      signerType: ["MULTI SIG", "MPC"],
    },
    BCH: {
      signerType: ["MULTI SIG", "MPC"],
    },
    DOGE: {
      signerType: ["MULTI SIG", "MPC"],
    },
    LTC: {
      signerType: ["MULTI SIG", "MPC"],
    },
    ALGORAND: {
      signerType: ["MPC"],
    },
    BASE: {
      signerType: ["MPC"],
    },
    BAHAMUT: {
      signerType: ["MULTI SIG", "MPC"],
    },
    PLAYA3ULL: {
      signerType: ["MPC"],
    },
    "COSMOSHUB-4":{
      signerType: ["MPC", "MULTI SIG"],
    },
    TELOS: {
      signerType: ["MPC", "MULTI SIG"],
    },
  },
  smart_consolidation_supported_chains: ["EVM", "TRON"],

  getsafetxgasapi: "https://safe-relay.mainnet.gnosis.io",
  appRestrictedaddresses: [
    {
      address: "0xe16C7165C8FeA64069802aE4c4c9C320783f2b6e",
      name: "COMP",
    },
    {
      address: "0x3d9819210A31b4961b30EF54bE2aeD79B9c9Cd3B",
      name: "Comptroller",
    },
    {
      address: "0xe65cdb6479bac1e22340e4e755fae7e509ecd06c",
      name: "cAAVE",
    },
    {
      address: "0x6C8c6b02E7b2BE14d4fA6022Dfd6d75921D90E4E",
      name: "cBAT",
    },
    {
      address: "0x70e36f6bf80a52b3b46b3af8e106cc0ed743e8e4",
      name: "cCOMP",
    },
    {
      address: "0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643",
      name: "cDAI",
    },
    {
      address: "0x4Ddc2D193948926D02f9B1fE9e1daa0718270ED5",
      name: "cETH",
    },
    {
      address: "0x7713DD9Ca933848F6819F38B8352D9A15EA73F67",
      name: "cFEI",
    },
    {
      address: "0xFAce851a4921ce59e912d19329929CE6da6EB0c7",
      name: "cLINK",
    },
    {
      address: "0x95b4ef2869ebd94beb4eee400a99824bf5dc325b",
      name: "cMKR",
    },
    {
      address: "0x158079Ee67Fce2f58472A96584A73C7Ab9AC95c1",
      name: "cREP",
    },
    {
      address: "0xF5DCe57282A584D2746FaF1593d3121Fcac444dC",
      name: "cSAI",
    },
    {
      address: "0x4b0181102a0112a2ef11abee5563bb4a3176c9d7",
      name: "cSUSHI",
    },
    {
      address: "0x12392F67bdf24faE0AF363c24aC620a2f67DAd86",
      name: "cTUSD",
    },
    {
      address: "0x35a18000230da775cac24873d00ff85bccded550",
      name: "cUNI",
    },
    {
      address: "0x39AA39c021dfbaE8faC545936693aC917d5E7563",
      name: "cUSDC",
    },
    {
      address: "0x041171993284df560249B57358F931D9eB7b925D",
      name: "cUSDP",
    },
    {
      address: "0xf650C3d88D12dB855b8bf7D11Be6C55A4e07dCC9",
      name: "cUSDT",
    },
    {
      address: "0xccF4429DB6322D5C611ee964527D42E5d685DD6a",
      name: "cWBTC",
    },
    {
      address: "0x80a2ae356fc9ef4305676f7a3e2ed04e12c33946",
      name: "cYFI",
    },
    {
      address: "0xB3319f5D18Bc0D84dD1b4825Dcde5d5f7266d407",
      name: "cZRX",
    },
    {
      address: "0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45",
      name: "SwapRouter02",
    },
    {
      address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      name: "WETH",
    },
    {
      address: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
      name: "UniswapV2Router02",
    },
    {
      address: "0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45",
      name: "UniswapV2Router2",
    },
    //  validator
    {
      address: "0x29f5406e20219f7e46806837e820cf7c2fa6d3ee",
      name: "Matic Foundation Node 4",
    },
    {
      address: "0xF5e574045298eE3A9870Ff712df9F9d325BcBB7A",
      name: "Matic Foundation Node 3",
    },
    {
      address: "0x1a9155ead92cd339e3ae789fd3a5dc60ae268634",
      name: "Matic Foundation Node 1",
    },
    {
      address: "0x8296DB6591F5762cc53E6E875F329A67886EE9d4",
      name: "Infosys",
    },
    {
      address: "0x144070a59fe5e7ebe963ca59b500e60485c235b9",
      name: "Anonymous 19",
    },
  ],
  refillWallet_support_chain: [
    "XRP",
    "ETH",
    "BSC",
    "BNB",
    "POLYGON",
    "XDAI",
    "ARBITRUM",
    "AVALANCHE",
    "OPTIMISM",
    "FANTOM",
  ],
  autoSignerEmail: [
    "machine@lmnl.app",
    "signer@lmnl.app",
    "firstanswers@lmnl.app",
    "mpcsignerlmnl@lmnl.app",
  ],
};
