import { Injectable } from '@angular/core';
import { UserTypeEnum } from '../entities/User';
import { CUSTODY_TYPE, UserRoleEnum, UserType, orgType as OrgType } from '../entities/members';
import { getUserProfile } from '@entities/Policy';

@Injectable({
  providedIn: 'root'
})
export class UserGuidingService {

  window: any = window;

  constructor() { }

  /**
   * user guiding
   * @param params 
   */
  async identifyUserForUserGuiding(params: { me: getUserProfile}) {
    const { me } = params;
    const orgDetails = me.organizations[0];
    const userRole = orgDetails.isowner ? 'Owner' : orgDetails.type == UserTypeEnum.ADMIN ? 'Admin' : 'Member';
    const userType = this.getUserType(orgDetails);
    const payload = {
      userId: me?.id,
      email: me?.email,
      name: me?.displayName,
      company_name: orgDetails?.name,
      role:userRole,
      created_at: new Date().toLocaleString(),
      product: userType
    }
    this.window.userGuiding.identify(me.email, payload);
  }

  /**
   * get userType
   * @param orgDetails 
   * @returns 
   */
  getUserType(orgDetails: any): UserType {
    const { orgType, type, custodyType } = orgDetails;
    if (orgType === OrgType.standard) {
        return UserType.NON_CUSTODY;
    }
    if (orgType === OrgType.management && type === UserRoleEnum.admin) {
        return UserType.CUSTODIAN;
    }
    if (orgType === OrgType.sub && custodyType === CUSTODY_TYPE.NONCUSTODY) {
        return UserType.SELF_CUSTODY;
    }
    return UserType.MANAGED_CUSTODY;
  }
}
