<li id="CB_Create_Wallet" appDisableClick *ngIf="component_for == 'menu' && disable_New_Wallet"
    (click)="open(new_Group_Wallet)">
    <i class="icon-add-wallet mr-2"></i><span>New Wallet</span>
</li>

<button appDisableClick *ngIf="component_for == 'wallets' && disable_New_Wallet" (click)="open(new_Group_Wallet)"
    class="btn btn-round btn-primary"> <i class="icon-add-wallet mr-2"></i> New Wallet </button>

<span *ngIf="component_for == 'No Wallet'">
    <a appDisableClick (click)="open(new_Group_Wallet)" class="text-primary cursor-pointer">Create New Wallet</a> now
</span>

<div appDisableClick [ngClass]="{'disabled': !disable_New_Wallet }" [disabled]="!disable_New_Wallet" role="button"
    *ngIf="component_for == 'onboard'">
    <div class="row no-gutters justify-content-md-center">
        <button (click)="open(new_Group_Wallet)" class="btn btn-outline-green px-3 w-100"
            href="https://calendly.com/lmnl/liminal-meet" target="_blank">
            <h2 class="d-flex align-items-center justify-content-center py-1 m-0">
                <i class="icon-wallets mr-2 text-black"></i>
                <span class="onboarding_btn text-black">Create Wallet</span>
            </h2>
        </button>
    </div>
</div>

<ng-template #new_Group_Wallet let-modal>

    <div *ngIf="step_link_device">
        <div class="modal-header border-0">
            <div class="d-inline">
                <h2 class="modal-title">
                    <img class="w-40" src="../../../../assets/images/trezor.svg" /> Link Device
                </h2>
            </div>
            <button type="button" class="close float-right" aria-label="Close"
                (click)="modal.dismiss('Cross click');closeModal()">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="container-fluid">
                <div class="text-center">
                    <div class="mt-5 pt-3 link_Icon_Circle">
                        <img class="w-40" src="../../../../assets/images/trezor.svg" />
                    </div>
                </div>
                <div class="text-center mt-5 pt-3">
                    <h6 class="text-muted">Link Device</h6>
                    <span class="text-muted">Please link your hardware device to setup new wallet</span>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
            <app-my-account class="w-100 mx-2" [link_component_for]="link_component_for"></app-my-account>
        </div>
    </div>

    <div *ngIf="step_add_member">
        <div class="modal-header border-0">
            <div class="d-inline">
                <h2 class="modal-title">
                    <img class="w-40" src="../../../../assets/images/trezor.svg" /> Add Members
                </h2>
            </div>
            <button type="button" class="close float-right" aria-label="Close"
                (click)="modal.dismiss('Cross click');closeModal()">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="container-fluid">
                <div class="text-center">
                    <div class="mt-5 pt-3 link_Icon_Circle">
                        <img class="w-40" src="../../../../assets/images/trezor.svg" />
                    </div>
                </div>
                <div class="text-center mt-5 pt-3">
                    <h6 class="text-muted">Add Members</h6>
                    <sapn class="text-muted">Oops, looks like you do not have any members to join your cold wallet.
                        Please add members to your account so they can be part of your cold wallet. Please contact your
                        account manager to add members to your account.</sapn>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
            <button class="btn btn-outline-primary  w-100 mx-2" data-original-title="" title=""
                (click)="modal.dismiss('Ok click');" type="submit">
                Ok</button>
        </div>
    </div>

    <div *ngIf="stepNumber == 1">
        <div class="modal-header">
            <div class="d-inline">
                <h2 class="modal-title">
                    <i class="icon-wallet mr-2"></i>New Wallet
                </h2>
            </div>
            <button type="button" aria-label="Close" class="close" (click)="modal.dismiss('Cross click');closeModal()">
                <i class="icon-close"></i>
            </button>
        </div>
        <div class="modal-body p-4">
            <div class="container-fluid">
                <div class="mt-2 pb-3">
                    <div class="d-inline">
                        <label class="mb-2 text-muted font-weight-500">
                            Wallet Name
                        </label>
                    </div>
                    <input #verifyWalletName="ngModel" autocomplete="off" class="form-control" [(ngModel)]="walletName"
                        placeholder="Enter wallet name" type="text" maxlength="50" pattern="[a-zA-Z0-9 ]*">
                </div>
                <div class="mt-2 pb-2">
                    <div class="d-flex font-weight-bold text-muted text-left">
                        <label class="mb-2 text-muted font-weight-500 w-100">
                            Protocol
                        </label>
                        <div class="px-2 flex-shrink-1"><label *ngIf="selected_Asset" class="link_style text-muted"
                                (click)="change_Asset()">Clear</label> </div>
                    </div>
                    <div ngbDropdown #myDrop="ngbDropdown" *ngIf="!selected_Asset">
                        <input autocomplete="off" class="form-control search-custom" [(ngModel)]="coin"
                            placeholder="Select protocol" ngbDropdownAnchor (focus)="myDrop.open()" type="text">
                        <ul ngbDropdownMenu class="digital_asset w-100 py-0">
                            <div *ngFor="let t of fltr.keys | keyvalue : returnZero | search_coin: coin ">
                                <button class="dropdown-content card w-100 mb-0 p-0 brd_Box"
                                    (click)="select_Asset(t.key,t)">
                                    <li ngbDropdownItem class="p-3">
                                        <div class="align-items-center">
                                            <div class="media border-after-xs align-items-center">
                                                <div class="d-flex align-items-center box_Circle_Qr p-2">
                                                    <span class="network text-dark text-left  font-size-14"
                                                        [ngClass]="t.key.toLowerCase() "></span>
                                                </div>
                                                <div class="d-flex flex-column text-left">
                                                    <span #name class="m-0 truncate_Name font-size-14 ">{{
                                                        getName(t.key) }}</span>
                                                    <span class="text-muted  text-left  font-size-12">{{
                                                        getSupportedCoins(t.key) && getSupportedCoins(t.key).length>0?
                                                        getSupportedCoinGroupText(t.key) : getSubName(t.key)
                                                        || getName(t.key)
                                                        }} </span>
                                                </div>
                                                <div class="media-body text-right">
                                                    <div class="position-relative d-flex justify-content-end ml-3">
                                                        <ul class="d-flex assets-group ml-2">
                                                            <li>
                                                                <span>
                                                                    <img class="img-fluid for-light img-round-border bg-white"
                                                                        [src]="t.key | getIcon" alt="">
                                                                </span>
                                                            </li>
                                                            <li
                                                                *ngIf="getSupportedCoins(t.key)&& getSupportedCoins(t.key).length>0">
                                                                <span
                                                                    *ngIf="getSupportedCoins(t.key)&& getSupportedCoins(t.key).length>0"
                                                                    class="mr-1 img-round-border last_count text-primary">
                                                                    + {{getSupportedCoins(t.key).length - 1}}
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </button>
                            </div>
                        </ul>
                    </div>
                    <div class="card card-border mb-0" *ngIf="selected_Asset">
                        <div class="card-body">
                            <div class="align-items-center">
                                <div class="media border-after-xs align-items-center">
                                    <div class="d-flex align-items-center box_Circle_Qr p-2">
                                        <span class="network text-dark text-left  font-size-14"
                                            [ngClass]="selected_asset_value.toLowerCase() "></span>
                                    </div>
                                    <div class="d-flex flex-column text-left">
                                        <span #name class="m-0 truncate_Name font-size-14 ">{{
                                            getName(selected_asset_value) }}</span>
                                        <span class="text-muted  text-left  font-size-12">{{
                                            getSupportedCoins(selected_asset_value)&&
                                            getSupportedCoins(selected_asset_value).length > 0 ?
                                            getSupportedCoinGroupText(selected_asset_value) :
                                            getSubName(selected_asset_value) || getName(selected_asset_value)}} </span>
                                    </div>
                                    <div class="media-body text-right">
                                        <div class="position-relative d-flex justify-content-end ml-3">
                                            <ul class="d-flex assets-group ml-2">
                                                <li>
                                                    <span>
                                                        <img class="img-fluid for-light img-round-border bg-white"
                                                            [src]="selected_asset_value | getIcon" alt="">
                                                    </span>
                                                </li>
                                                <li style="margin-top: 10px;"
                                                    *ngIf="getSupportedCoins(selected_asset_value)&& getSupportedCoins(selected_asset_value).length>0">
                                                    <span style="display: initial; padding: 8.5px;"
                                                        *ngIf="getSupportedCoins(selected_asset_value)&& getSupportedCoins(selected_asset_value).length>0"
                                                        class="mr-1 img-round-border last_count text-primary">
                                                        + {{getSupportedCoins(selected_asset_value).length - 1}}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-3" *ngIf="selected_asset_value?.toLowerCase() === 'dot'">
                    <div class="d-inline-flex ">
                        <label class=" text-muted"><b>Notes</b></label>
                    </div>
                    <div class=" mt-2">
                        <ol class="pl-3">
                            <li class="text-muted font-size-12 ">All members must have <span
                                    class="text-red font-size-12">minimum balance of 1 DOT</span> to create Polkadot
                                wallet.</li>
                            <li class="text-muted font-size-12 mt-1">When sending transactions the initiator needs to
                                have minimum reserve amount X DOT to initiate transaction.</li>
                            <li class="text-muted font-size-12 mt-1">Only initiator can cancel the transaction.</li>
                        </ol>

                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
            <button
                [disabled]="!walletName.trim() || (verifyWalletName.invalid && (verifyWalletName.dirty || verifyWalletName.touched)) || !selected_asset_value || !selected_Asset"
                class="btn btn-primary  w-100" data-original-title="" title=""
                (click)="onNextBtnClick(); populateAllMultiSigSigners()" type="submit">
                Next
            </button>
        </div>
    </div>

    <div *ngIf="stepNumber==2 && walletCreateStep === MembersType.INITIATORS">
        <app-modal-header title="New Wallet" (onClose)="modal.dismiss('Cross click');closeModal()"
            [showBackButton]="true" (onBackPressed)="onBackButtonClick()"></app-modal-header>

        <div class="modal-body p-4">
            <app-mobile-teams [type]="MembersType.INITIATORS" [teamsListData]="teamsListData" [orgProfile]="orgProfile"
                (onTeamConfigUpdated)="onInitiatorsSelect($event)"
                [teamConfig]="mpcWalletData?.initiators"></app-mobile-teams>
            <div class="container-fluid mb-2 mt-4" *ngIf="isMultisigHybridCustody">
                <div class="d-flex mb-2">
                    <span class="font-weight-bold text-muted mr-2">Signers</span>
                    <div class="d-flex"
                        [ngbTooltip]="'Signers are distinct individuals who are not members of the initiating team.'"
                        placement="right">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                            class="bi bi-info-circle text-center" style="align-self: center;" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path
                                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                    </div>
                </div>
                <div class="mb-3 mt-1">
                    <ng-multiselect-dropdown [settings]="signersDropdownSettings" name="Signer"
                        placeholder="Select Signers" (onSelect)="onMemberSelect($event)"
                        (onDeSelect)="onMemberDeSelect($event)" [data]="filteredMultisigSigners" class="test">
                        <ng-template #optionsTemplate let-item let-option="option" let-id="id"
                            let-isSelected="isSelected" class="d-flex ms-2 selected-item">
                            <div class="d-flex" [style]="isSelected?'display:none !important':''">
                                <div class="d-flex align-items-center box_Circle_Qr p-2">
                                    <img class="img-fluid for-light rounded-circle user-image "
                                        [src]="getMultisigSigners[id].phptoURL" alt="">
                                </div>
                                <div class="details d-flex flex-column ">
                                    <div class="text-left p-0 mb-auto mt-auto">
                                        <span #name class="m-0 truncate_Name">{{ getMultisigSigners[id].displayName
                                            }}</span>
                                        <div *ngIf="getMultisigSigners[id].isDisabled">
                                            <span class="font-size-12">Device not linked for this chain</span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </ng-template>
                        <ng-template #optionSelectedTemplate let-option="option" let-id="id" class="selected-item">
                            <div class="d-flex">
                                <div class="d-flex align-items-center box_Circle_Qr">
                                    <img class="img-fluid for-light rounded-circle-white-chip user-image mr-2"
                                        [src]="getMultisigSigners[id].phptoURL" alt="">
                                </div>
                                <div class="details d-flex align-items-center">
                                    <div class="col text-left p-0">
                                        <span #name class="m-0 truncate_Name ">{{ option }}</span>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
            <button *ngIf="multisigCustodyType !== orgFeatureType.MULTISIG_HYBRID" [disabled]="!isValidInitiators"
                class="btn btn-primary w-100" data-original-title="" title="" (click)="onNextBtnClick()" type="submit">
                Next
            </button>
            <button *ngIf="multisigCustodyType === orgFeatureType.MULTISIG_HYBRID"
                [disabled]="disableInitiatorBtnForHybrid()" class="btn btn-primary w-100"
                data-original-title="" title="" (click)="onNextBtnClick()" type="submit">
                Next
            </button>
        </div>
    </div>

    <div *ngIf="stepNumber==3">
        <app-modal-header title="New Wallet" (onClose)="modal.dismiss('Cross click');closeModal()"
            [showBackButton]="true" (onBackPressed)="onBackButtonClick()"></app-modal-header>

        <div class="modal-body p-4 pt-1">
            <div class="container-fluid">
                <div class="w-100 ml-1 font-weight-500 text-muted mb-1">Wallet</div>
                <div class="card card-border mb-4 border-radius" *ngIf="selected_Asset">
                    <div class="card-body">
                        <div class="align-items-center">
                            <div class="media border-after-xs align-items-center">
                                <div class="">
                                    <div class="position-relative d-flex justify-content-end ml-3">
                                        <ul class="d-flex assets-group ml-2">
                                            <li>
                                                <span>
                                                    <img class="img-fluid for-light img-round-border bg-white"
                                                        [src]="selected_asset_value | getIcon" alt="">
                                                </span>
                                            </li>
                                            <li style="margin-top: 10px;"
                                                *ngIf="getSupportedCoins(selected_asset_value)&& getSupportedCoins(selected_asset_value).length>0">
                                                <span style="display: initial; padding: 8.5px;"
                                                    *ngIf="getSupportedCoins(selected_asset_value)&& getSupportedCoins(selected_asset_value).length>0"
                                                    class="mr-1 img-round-border last_count text-primary">
                                                    + {{getSupportedCoins(selected_asset_value).length - 1}}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="pl-3">
                                    <div class="d-flex align-items-center box_Circle_Qr ">
                                        <span #name class="m-0 truncate_Name font-size-14 ">{{
                                            walletName }}</span>
                                    </div>
                                    <div class="d-flex flex-row text-left">
                                        <span class="network text-dark text-left  font-size-14"
                                            [ngClass]="selected_asset_value.toLowerCase() "></span>

                                        <span class="text-muted  text-left  font-size-12">{{
                                            getSupportedCoins(selected_asset_value)&&
                                            getSupportedCoins(selected_asset_value).length > 0 ?
                                            getSupportedCoinGroupText(selected_asset_value) :
                                            getName(selected_asset_value) |
                                            titlecase}} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container>
                <div class="card card-border py-3 px-2">
                    <ng-container *ngIf="mpcWalletData.initiators?.team">
                        <div class="d-flex">
                            <div class="d-flex flex-column p-2">
                                <span class="font-weight-500 mb-1">Initiators</span>
                                <span class="font-weight-500 text-muted">{{mpcWalletData.initiators?.team.name}}</span>
                            </div>
                            <span
                                class="ml-auto mt-2 mr-2 font-weight-bold text-muted">{{mpcWalletData.initiators?.selectedMembersCountText}}</span>
                        </div>
                        <app-member-list [members]="mpcWalletData.initiators?.team?.teamMembers"
                            [removeOuterBorder]="true"></app-member-list>
                    </ng-container>

                    <ng-container *ngIf="selectedMultisigSigners.length">
                        <div class="d-flex mt-4">
                            <div class="d-flex flex-column p-2">
                                <span class="font-weight-500 mb-1">Signers</span>
                            </div>
                        </div>
                        <app-member-list [members]="transformMultisigSigners()"
                            [removeOuterBorder]="true"></app-member-list>
                    </ng-container>
                </div>
            </ng-container>
            <app-firewall-warning [displayWarning]="displayFirewallWarning"></app-firewall-warning>
        </div>
        <div class="modal-footer border-0">
            <button class="btn btn-primary  w-100" data-original-title="" title="" [disabled]="loading"
                (click)="!loading && createWalletRequest();loading=true;" type="submit">
                Confirm
            </button>
        </div>
    </div>

    <div *ngIf="stepNumber==4" class="modal-custom">
        <div class="modal-header border-0">
            <h2 class="modal-title">
                <i class="icon-wallet mr-2"></i> New Wallet
            </h2>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');"> <i
                    class="icon-close"></i> </button>
        </div>
        <div class="modal-body d-flex align-items-center bg-white">
            <div class="row">
                <div class="col-12">
                    <div class="mb-3 position-relative">
                        <div class="background_success text-center"> <img class="w-50"
                                src="../../../assets/images/check-outline-success.gif"> </div>
                    </div>
                    <div class="mb-5 text-center">
                        <div class="h5">Success</div>
                        <div class="text-muted">We are processing your request to create a new wallet. It will show up in your list of wallets
                            within 2 minutes. If not, please try again or contact us.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
            <button title="" type="submit" class="btn btn-outline-primary w-100"
                (click)="modal.dismiss('Cross click');"> Done </button>
        </div>
    </div>
</ng-template>