import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { signMessage } from "../helpers/trezor/messageSigner";
import { BehaviorSubject } from "rxjs";
import { AuthServiceJWT } from "./auth.service";
import { LocalForageService } from "./localforage.service";
import { Policy } from "@entities/Policy";
const policyTypes = [
  "whitelist_address",
  "wallet_velocity",
  "wallet_threshold",
];
interface ApiResponse {
  success: boolean;
  message: string;
}
@Injectable({
  providedIn: "root",
})
export class PolicyService {
  walletPolicies: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor(
    private HttpService: HttpService,
    private auth: AuthServiceJWT,
    private localForage: LocalForageService
  ) {
    localForage
      .getAll(function (key, value) {
        if (key.startsWith("policies_")) {
          return true;
        }
        return false;
      })
      .then((data) => {
        this.walletPolicies.next(data);
      });
  }

  setWalletPolicies(walletId: string, policies: Policy[]) {
    const current = this.walletPolicies.value;
    const data = {
      policies,
      policyKey: `policies_${walletId}`,
      timestamp: Date.now(),
    };
    this.localForage.setItem(`policies_${walletId}`, data).then(() => {
      this.walletPolicies.next({ ...current, [`policies_${walletId}`]: data });
    });
    console.info("setting wallet policies");
  }

  async getWalletPolicies(walletId: number): Promise<Policy[]> {
    const cached = (await this.localForage.getItem(`policies_${walletId}`)) as {
      policies: Policy[];
      timestamp: number;
    };
    if (cached) {
      console.info(((Date.now() - cached.timestamp) / 1000) * 60);
    }
    if (!cached || Date.now() - cached.timestamp > 1000 * 60 * 2) {
      // 2 min cache
      console.info("fetching new policies");
      await this.localForage.removeItem(`policies_${walletId}`);
      this.HttpService.getWalletPolicies(walletId).subscribe((policies) => {
        this.setWalletPolicies(walletId.toString(), policies);
      });
      return null;
    }
    console.info("returning cached policies", cached);
    return this.walletPolicies.value[`policies_${walletId}`].policies;
  }

  /**
   * Retrieves the wallet policies synchronously.
   * 
   * @param walletId - The ID of the wallet.
   * @param ignoreCache - Optional. If true, fetches new policies instead of using the cached ones.
   * @returns A promise that resolves to an array of policies.
   */
  async getWalletPolicySync(walletId, ignoreCache?: boolean): Promise<Policy[]> {
    return new Promise(async (resolve) => {
      // if ignoreCache is true, fetch new policies
      if (ignoreCache) {
        await this.localForage.removeItem(`policies_${walletId}`);
      }

      const cachedPolicies = (await this.localForage.getItem(
        `policies_${walletId}`
      )) as {
        policies: Policy[];
        timestamp: number;
      };

      if (!cachedPolicies || Date.now() - cachedPolicies.timestamp > 1000 * 60 * 2) {
        // 2 min cache
        console.info("fetching new policies");
        await this.localForage.removeItem(`policies_${walletId}`);
        this.HttpService.getWalletPolicies(walletId).subscribe((policies) => {
          this.setWalletPolicies(walletId.toString(), policies);
          return resolve(policies);
        });
      } else {
        console.info("returning cached policies", cachedPolicies);
        return resolve(cachedPolicies.policies);
      }
    });
  }

  async addPolicy(
    walletId,
    walletName,
    name,
    type,
    address,
    amount,
    timePeriod,
    device,
    policyRequest,
    memo
  ): Promise<ApiResponse> {
    try {
      const signResponse = await this.signPolicyMessage(
        walletName,
        type,
        address,
        amount,
        timePeriod,
        device,
        policyRequest
      );

      return new Promise((resolve, reject) => {
        this.auth.writeAccess("policies:add").subscribe((accessToken) => {
          this.HttpService.addPolicy(
            walletId,
            name,
            type,
            "",
            signResponse.message,
            "",
            accessToken,
            memo
          )
            .toPromise()
            .then((result) => {
              resolve(result);
            })
            .catch((error) => {
              reject(error);
            });
        });
      });
    } catch (e) {
      console.error(e);
      return Promise.reject({
        success: false,
        message: "message signing failed",
      });
    }
  }

  async approvePolicy(
    policiestxdata,
    type,
    address,
    timePeriod,
    device,
    policyRequest
  ): Promise<ApiResponse> {
    try {
      const signResponse = await this.signPolicyMessage(
        policiestxdata.wallet.name,
        type,
        address,
        policiestxdata.sub_type.toLowerCase() === "enable_app"
          ? policiestxdata.name
          : policiestxdata.amount,
        timePeriod,
        device,
        policyRequest
      );
      return new Promise((resolve, reject) => {
        this.auth.writeAccess("policies:approve").subscribe((accessToken) => {
          //   console.log("accessToken-------------",accessToken)
          this.HttpService.approvePolicy(
            policiestxdata.id,
            policiestxdata.wallet.id,
            "0x" + "",
            signResponse.message,
            "",
            accessToken
          )
            .toPromise()
            .then((result) => {
              resolve(result);
            })
            .catch((error) => {
              reject(error);
            });
        });
      });
    } catch (e) {
      console.log(e);
      return Promise.reject({
        success: false,
        message: "message signing failed",
      });
    }
  }

  async removePolicy(
    polictList,
    type,
    address,
    timePeriod,
    device,
    policyRequest
  ): Promise<ApiResponse> {
    try {
      const signResponse = await this.signPolicyMessage(
        polictList.walletname,
        type,
        address,
        polictList.amount,
        timePeriod,
        device,
        policyRequest
      );
      return new Promise((resolve, reject) => {
        this.auth.writeAccess("policies:remove").subscribe((accessToken) => {
          //   console.log("accessToken-------------",accessToken)
          this.HttpService.rempovePolicy(
            polictList.policyid,
            polictList.walletid,
            "0x" + "",
            signResponse.message,
            "",
            accessToken
          )
            .toPromise()
            .then((result) => {
              resolve(result);
            })
            .catch((error) => {
              reject(error);
            });
        });
      });
    } catch (e) {
      console.error(e);
      return Promise.reject({
        success: false,
        message: "message signing failed",
      });
    }
  }

  async signPolicyMessage(
    walletName,
    type,
    address,
    amount,
    timePeriod,
    device,
    policyRequest
  ): Promise<any> {
    const today = new Date().toISOString().slice(0, 10);
    let message = "";
    if (policyRequest === "remove policy") {
      if (type === "whitelist_address") {
        message = "Remove " + address + " in " + walletName + " at " + today;
      } else if (type === "wallet_threshold") {
        message =
          "Remove transaction limit from wallet " + walletName + " at " + today;
      } else if (type === "wallet_velocity") {
        message =
          "Remove spending limit from wallet " + walletName + " at " + today;
      } else {
        return Promise.reject({
          success: false,
          message: "invalid policy type",
        });
      }
      return { message: message };
    } else {
      if (type === "whitelist_address") {
        message = "Whitelist:" + address + " in " + walletName + " at " + today;
      } else if (type === "wallet_threshold") {
        message = `Update transaction limit to ${amount} in ${walletName} at ${today}`;
      } else if (type === "wallet_velocity") {
        message = `Update spending limit to ${amount} per ${timePeriod} in ${walletName} at ${today}`;
      } else if (type === "enable_app") {
        message = `Enable appid ${address} name ${amount} in Trade at ${today}`;
      } else {
        return Promise.reject({
          success: false,
          message: "invalid policy type",
        });
      }
      return { message: message };
    }
  }
}
