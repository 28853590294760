import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccessControlService {
  private isCustodyOrg: boolean;

  constructor() { }

  setIsCustodyOrg(value: boolean) {
    this.isCustodyOrg = value;
  }

  getIsCustodyOrg(): boolean {
    return this.isCustodyOrg;
  }
}
