import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { TeamSigningType, TeamSigningSubType, TeamsV2Request } from '../../entities';
import { AuthServiceJWT } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { HttpService } from '../../services/http.service';
import { MembersService } from '../../services/members.service';
import { TeamsService } from '../../services/teams.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { isCustodyOrg } from "../../helpers/org.utils"
import { ErrorPrompt, SuccessPrompt } from '../custom-prompt/custom-prompt.service';
import { ErrorPromptPayload, SuccessPromptPayload } from '../../constants/promptMessages';

@Component({
  selector: 'app-add-team-v2',
  templateUrl: './add-team-v2.component.html',
  styleUrls: ['./add-team-v2.component.scss']
})
export class AddTeamV2Component implements OnInit {

  // Enums intialisation for use down the line
  TeamSigningType = TeamSigningType;
  TeamSigningSubType = TeamSigningSubType;

  searchDropdownSettings: IDropdownSettings = {};

  members: any;
  filteredMembers: any;
  user: any;
  teamName: string = '';

  selected_type: any = {};

  addTeamForm: FormGroup;
  teamNamePattern: string = "[a-zA-Z0-9 ]*"

  @Input() orgProfile: any;
  @Output() teamCreated: EventEmitter<boolean> = new EventEmitter();

  isLoading: boolean = false;
  status: string = '';
  errorMessage: string = ''
  isSigningTypeSigning: boolean = false;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private memberservice: MembersService,
    private data: DataService,
    public authService: AuthServiceJWT,
    private httpService: HttpService,
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private teamService: TeamsService
  ) {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.init();
  }

  async init() {
    let profile = this.data.getUserProfile.getValue();
    await this.getMember(profile.organizations[0].id);
  }

  async getMember(orgId) {
    let members = await this.httpService.getOrgMembers(orgId);
    this.members = members.members;

    this.members = this.members.filter((member) => member.userType == 1);
    this.filteredMembers = Array.isArray(this.members) ? [...this.members] : [];
  }

  ngOnInit(): void {
    this.memberservice.resetMember();

    this.searchDropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "displayName",
      enableCheckAll: false,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: "",
    };

    this.initTeamForm();

    this.subscribeToSigningTypeChanges();

    if (this.isCustodyOrg()) this.getControl('teamSigningType').setValue(TeamSigningType.NON_SIGNING)
  }

  initTeamForm() {
    this.addTeamForm = this.formBuilder.group({
      teamName: ['', [Validators.required, Validators.pattern(this.teamNamePattern)]],
      teamSigningType: ['', Validators.required],
      selectedMembers: [null, Validators.required]
    })

  }


  subscribeToSigningTypeChanges() {
    this.subscriptions.add(this.addTeamForm.get('teamSigningType').valueChanges.subscribe(value => {
      this.isSigningTypeSigning = value === TeamSigningType.SIGNING;

      if (TeamSigningType.NON_SIGNING === value) {
        this.addTeamForm.get('teamSigningSubType')?.setValue(undefined)
        this.addTeamForm.removeControl('teamSigningSubType')
        this.filteredMembers = this.members && this.members.length && [...this.members] || [];
      }
      else if (TeamSigningType.SIGNING === value) {
        // Defaulting this to MPC for the time-being
        this.addTeamForm.addControl('teamSigningSubType', new FormControl(TeamSigningSubType.MPC, Validators.required))
        this.filterMembersBySubType()
      }

      this.clearMembersDropdown();

    }));
  }


  filterMembersBySubType() {
    this.filteredMembers = this.members.filter((member) => {
      return member?.providers?.find((member) => member == "mobile_mpc");
    })
      .sort((a, b) => a.displayName.localeCompare(b.displayName));

  }

  clearMembersDropdown() {
    let membersDropdown = this.getControl('selectedMembers')
    membersDropdown.setValue(null);
    membersDropdown.setErrors({ 'required': true });
    membersDropdown.markAsUntouched();
  }

  get getMembers() {
    return this.members.reduce((acc, member) => {
      acc[member.id] = member;
      return acc;
    }, {});
  }

  async submitTeam() {
    // mark as touched to display error messages
    Object.values(this.addTeamForm.controls).forEach(control => {
      control.markAsTouched();
    });

    if (this.addTeamForm.invalid) {
      return
    }

    this.isLoading = true;

    // Structure data
    let { teamName, teamSigningType, teamSigningSubType, selectedMembers } = this.addTeamForm.value;
    let members = selectedMembers.map((member: { id: number, displayName: string }) => member.id);

    // Prepare data
    let team = new TeamsV2Request(teamName, teamSigningType, members, teamSigningSubType);

    this.teamService.createTeam(team).subscribe((data) => {
      this.isLoading = false
      this.resetForm();
      this.status = 'success';
      SuccessPrompt.fire(SuccessPromptPayload.TEAM_ADDED,() => {
        // Additional actions after modal closed
        this.resetAndCloseModal();
      });
      // Emit save
      this.teamCreated.emit(true);

    }, ({ error }) => {
      this.errorMessage = error.message
      this.status = 'error';
      const errorPayload = ErrorPromptPayload.TEAM_ADD_FAILED;
      errorPayload.text = error.message;
      ErrorPrompt.fire(errorPayload,() => {
        this.resetAndCloseModal();
      });      
      this.isLoading = false
    })
  }

  resetForm(): void {
    this.addTeamForm.reset();
  }

  getControl(controlName: string): FormControl {
    return this.addTeamForm.get(controlName) as FormControl;
  }

  isControlInvalidAndTouched(controlName: string): boolean {
    const control = this.getControl(controlName);
    return control.invalid && control.touched;
  }

  isInvalidPattern() {
    const inputField = this.getControl('teamName');
    return inputField.touched && inputField.hasError('pattern');
  }

  getSelectedCount() {
    return this.getControl("selectedMembers").value
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  isCustodyOrg(): boolean {
    return isCustodyOrg(this.orgProfile)
  }

  resetAndCloseModal() {
    this.resetForm();
    this.activeModal.dismiss('Cross click');
  }
}
