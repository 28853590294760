// import { environment } from "src/environments/environment";

import { environment } from "src/environments/environment";

const commonUrl = environment.apiUrl;

// Convert the event names to more readable and user friendly strings
export const eventsArray = [
  { event: "new-tx", userEvent: "New Transaction" },
  { event: "txn-initiate", userEvent: "Transaction Initiate" },
  {
    event: "txn-initiate-approval",
    userEvent: "Transaction Initiate Approval",
  },
  { event: "txn-initiated", userEvent: "Transaction Initiated" },
  { event: "txn-firewall-blocked", userEvent: "Transaction Firewall Blocked" },
  { event: "txn-firewall-allowed", userEvent: "Transaction Firewall Allowed" },
  { event: "txn-approval-added", userEvent: "Transaction Approval Added" },
  { event: "txn-signature-added", userEvent: "Transaction Signature Added" },
  { event: "txn-signed", userEvent: "Transaction Signed" },
  { event: "txn-broadcasted", userEvent: "Transaction Broadcasted" },
  { event: "txn-confirmed", userEvent: "Transaction Confirmed" },
  { event: "txn-rejected", userEvent: "Transaction Rejected" },
  { event: "policy-config-update", userEvent: "Policy Config Update" },
  { event: "policy-config-add", userEvent: "Policy Config Add" },
  { event: "policy-approved", userEvent: "Policy Approved" },
  { event: "policy-initiated", userEvent: "Policy Initiated" },
  { event: "policy-rejected", userEvent: "Policy Rejected" },
  { event: "policy-created", userEvent: "Policy Created" },
  { event: "policy-removed", userEvent: "Policy Removed" },
  { event: "policy-remove-approved", userEvent: "Policy Remove Approved" },
  { event: "policy-remove-initiated", userEvent: "Policy Remove Initiated" },
  { event: "login-success", userEvent: "Login Success" },
  { event: "logout-success", userEvent: "Logout Success" },
  { event: "user-invite", userEvent: "User Invite" },
  { event: "user-invite-accept", userEvent: "User Invite Accept" },
  { event: "user-risk-disclosure", userEvent: "User Risk Disclosure" },
  { event: "team-create", userEvent: "Team Create" },
  { event: "team-update", userEvent: "Team Update" },
  { event: "api-key-created", userEvent: "API key created" },
  { event: "api-key-deleted", userEvent: "API key deleted" },
  { event: "api-key-enabled", userEvent: "API key enabled" },
  { event: "api-key-disabled", userEvent: "API key disabled" },
  { event: "api-key-rotated", userEvent: "API key rotated" },
  { event: "api-key-ip-added", userEvent: "API key IP added" },
  { event: "api-key-ip-disabled", userEvent: "API key IP disabled" },
  { event: "api-key-ip-enabled", userEvent: "API key IP enabled" },
  { event: "wallet-created", userEvent: "Wallet Created" },
  { event: "wallet-request-created", userEvent: "Wallet Request Created" },
  { event: "wallet-request-approved", userEvent: "Wallet Request Approved" },
  { event: "wallet-request-rejected", userEvent: "Wallet Request Rejected" },
  { event: "wallet-creation-initiated", userEvent: "Wallet Creation Initiated" },
  { event: "wallet-creation-success", userEvent: "Wallet Creation Successful" },
  { event: "wallet-creation-failed", userEvent: "Wallet Creation Failed" },
  {event: "exchange-connection-added", userEvent: "Exchange Connection Added"},
  {event: "exchange-connection-update", userEvent: "Exchange Connection Updated"},
];

/**
 * Retrieves the flag emoji corresponding to a given ISO 3166-1 alpha-2 country code.
 *
 * @param countryCode - A two-letter ISO 3166-1 alpha-2 country code.
 * @returns The corresponding flag emoji or undefined if the country code is invalid.
 */
export const getFlagEmoji = (countryCode: string): string | undefined => {
  if (countryCode.length !== 2) {
    console.error(
      "Invalid country code. Please provide a valid ISO 3166-1 alpha-2 country code."
    );
    return undefined;
  }

  // Convert the country code to uppercase and get the Unicode code points
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char) => 127397 + (char as any).charCodeAt());

  // Convert the Unicode code points to a string representing the flag emoji
  return String.fromCodePoint(...codePoints);
};

/**
 * Constructs a URL for querying audit logs based on specified parameters.
 *
 * @param search - Optional. A search query string to filter results.
 * @param event - Optional. An event identifier to filter results.
 * @param country - Optional. A country code to filter results.
 * @param startDate - Optional. Start date for filtering results.
 * @param endDate - Optional. End date for filtering results. If not provided, it defaults to startDate.
 * @param page - Optional. Page number for pagination. Defaults to 1.
 * @param orgId - Required. The organization identifier used as the base for constructing the URL.
 * @param subOrgId - Optional. Sub-organization identifier for additional filtering.
 * @returns The constructed URL for querying audit logs.
 */
export function constructUrl(
  page?: number,
  pageSize?: number,
  orgId?: number,
  search?: string,
  event?: string,
  user?: string,
  startDate?: string,
  endDate?: string
): string | undefined {
  if (!orgId) {
    alert("orgId is required");
    return undefined;
  }

  const baseParams = `${commonUrl}/org/orgId/${orgId}/audit-logs?page=${(page || 1) - 1}&pageSize=${pageSize || 10}`;

  const queryParams = [baseParams];
  // Add optional parameters to the array if they are provided

  if (search) {
    queryParams.push(`search=${search}`);
  }
  if (event) {
    queryParams.push(`identifiers=${event}`);
  }
  if (user) {
    queryParams.push(`user=${user}`);
  }
  if (startDate) {
    // If endDate is not provided, default it to startDate
    endDate = endDate == null ? startDate : endDate;
    queryParams.push(`startDate=${startDate}&endDate=${endDate}`);
  }

  const url = queryParams.join("&");

  return url;
}
