import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
const buffer = require('../node_modules/buffer/index').Buffer;

Buffer.prototype.readBigUInt64LE = buffer.prototype.readBigUInt64LE;
Buffer.prototype.writeBigUInt64LE = buffer.prototype.writeBigUInt64LE;

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// FreshSales Bot
if (environment.buildType === 'vaults') {
  const script = document.createElement('script');
  script.src = '//in.fw-cdn.com/31567379/780009.js';
  script['chat'] = 'true';
  script['widgetId'] = '16d2e011-abcb-4655-93a7-c0c336f6356b';
  document.head.appendChild(script);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
