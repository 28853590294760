<button appDisableClick *ngIf="invite_component_for == 'invite_members'" class="btn btn-outline-green px-3 w-100"
  (click)="open(invite_members)" role="button">
  <h2 class="d-flex align-items-center justify-content-center py-1 m-0">
    <i class="icon-add-member text-black mr-2"></i>
    <span class="onboarding_btn_black align-top text-black">Send Invite</span>
  </h2>
</button>
<a appDisableClick (click)="open(invite_members)" class="btn btn-primary btn-round btn-md" ngbTooltip="Invite Members" *ngIf="invite_component_for == 'org_invite_members'">
  <i class="icon-add-alt"></i>
  <span id="CB_Invite_Members" class="d-inline ml-1">Invite</span>
</a>
<a appDisableClick (click)="open(invite_members)" class="btn btn-primary btn-round btn-md" ngbTooltip="Invite Members" *ngIf="invite_component_for == 'user_manage_invite_member'">
  <i class="icon-add-alt"></i>
  <span id="CB_Invite_Members" class="d-inline ml-1">Invite</span>
</a>

<ng-template #invite_members let-modal>
  <div *ngIf="!success">
    <div class="modal-header">
      <h2 class="modal-title">
        <i class="icon-add-member mr-2"></i>
        Invite User
      </h2>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
        <i class="icon-close"></i>
      </button>
    </div>
    <div class="modal-body invite-members">
      <div>
        <div class="row">
          <div class="col-12">
            <div>
              <div class="form-group mb-4">
                <div class="d-inline">
                    <label>Name</label>
                </div>
                <input autocomplete="off" #inputName placeholder="Enter Name" [(ngModel)]="inviteBodyData.name"
                  value="{{inviteBodyData.name}}" class="form-control" type="text" (ngModelChange)="validateInput()">
              </div>

              <div class="form-group mb-4">
                <div class="d-inline">
                    <label>Email</label>
                </div>
                <input autocomplete="off" #inputemail [(ngModel)]="inviteBodyData.email" placeholder="Enter Email"
                  value={{inviteBodyData.email}} class="form-control" [pattern]="settings.pattern" type="email"
                  (ngModelChange)="emailValidator()">
                </div>

              <div class="mt-2 pb-3" >
                <span class="font-weight-bold text-muted" >User Type</span>
                <div  class="mb-4 mt-1" style="margin-left: auto !important;" ngbDropdown #myDropInitiator="ngbDropdown" >
                    <div class="d-flex input-dropdown-border input-bk" >
                        <input autocomplete="off"  class="form-control search-custom  test " id="dropdownManual"
                            ngbDropdownAnchor (focus)="myDropInitiator.open(); focusTotInit=true" (blur)="focusTotInit=false"
                            type="text" placeholder="Select User type" [value]="selected_type?.name" (keydown)="$event.preventDefault()"/>

                        <img class="accordion-panel-icon"
                        (click)="focusTotInit ? myDropInitiator.close() : myDropInitiator.open()" [class.active-icon]="focusTotInit"
                        src="../../../../../assets/images/down_arrow.svg" alt="arrow"
                        [style.transform]="focusTotInit ? 'rotate(180deg)' : 'rotate(0deg)'" />
                    </div>
                    <ul ngbDropdownMenu class="whitelistscroll py-0" >
                        <div class="d-flex " *ngFor="let data of memberType" style="border-bottom: 1px solid rgba(0, 0, 0, 0.15)">
                            <button class="dropdown-content card brd_Box p-0 m-0" style="width: 100%; border: none !important;"
                                (click)="onDropDownBtnClick(data)">
                                <li ngbDropdownItem class="p-3 text-wrap">
                                    <div class="d-flex  flex-column" >
                                        
                                        <div class="text-left text-margin">
                                                {{data.name}}
                                        </div>
                                        <div class="ml-auto"></div>
                                    </div>
                                </li>
                            </button>
                        </div>
                    </ul>
                </div>
            </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer border-0">
      <button 
      [ngClass]="{'disabled': !inviteBodyData.name || !inviteBodyData.email || !inviteBodyData.role || !ifValidEmail }" [disabled]="!inviteBodyData.name || !inviteBodyData.email || !inviteBodyData.role || !ifValidEmail"
        class="btn btn-outline-green w-100 " role="button" (click)="inviteMembers()">
        <i class="icon-add-member mr-2"></i>
        Send Invite
      </button>
    </div>
  </div>
</ng-template>