import { Policy } from "./Policy";
import { StatusENUM } from "./misc";
import { MinimumRequiredInitiators } from "../constants/wallet.const";

export interface Wallet {
  isArchived: any;
  ScheduleTransactions?: any;
  id: any;
  type: string;
  config: string;
  coin: string;
  orgid: number;
  balance?: string;
  name: string;
  walletid?: string;
  access?: string;
  balanceUSD?: number;
  xpub?: string;
  path?: string;
  address?: string;
  chain: string;
  walletKeys?: MultiSigWalletKeys[];
  identifier?: string;
  decimalplaces?: number;
  policy?: Policy[];
  provider?: string;
  parentchain?: string;
  imageurl?: string;
  subtype: string;
  tokenType?: number;
  tags?: any;
  tag?: 'refill' | 'hot' | 'cold';
  version: number;
  coinfullname?: string;
  suspendedStatus?: WalletSuspendStatus;
  walletUsers?: WalletUsers[];
}

export interface WalletUsers {
  id: number;
  walletid: number;
  userid: number;
  status: number;
  type: string;
  displayName: string;
  photoURL: string;
  email: string;
  userType: number;
}

export interface Members {
  id: string;
  Name: string;
  type: string;
}

export interface WalletGrouped {
  title: string;
  data: Wallet[];
}

export interface MultiSigWalletKeys {
  xpub: string;
  path: string;
  userid: string;
  ismine: boolean;
  coin: string;
  displayName: string;
  photoURL: string;
  type: string;
  provider: string;
}
export const Wallets = [];

export interface getStakingWalletList {
  address: string;
  assetBalance: string;
  assetBalanceUsd: string;
  assetIdentifier: string;
  assetSymbol: string;
  balance: string;
  balanceUSD: string;
  chain: string;
  coin: string;
  config: string;
  id: number;
  isStakingEnablled: number;
  name: string;
  orgid: number;
  parentchain: string;
  subtype?: any;
  type: string;
  walletid: number;
  stakingInfo: any;
}

export class MPCTeamConfig {
  constructor(public isCustodyOrg: boolean, public team?: any, public minMembersRequired?: number
  ) {}
  get totalMembersCountText(): string {
    if (this.team?.teamMembers?.length > 0) {
      return `${this.team?.teamMembers.length} Selected`;
    }
    return "";
  }
  get selectedMembersCountText(): string {
    if (
      this.minMembersRequired !== null &&
      this.minMembersRequired !== undefined &&
      this.team?.teamMembers?.length > 0
    ) {
      return `${this.minMembersRequired} of ${this.team?.teamMembers.length}`;
    }
    return "";
  }

  get isValidTeam() {
    if (this.team) {
      if (
        this.minMembersRequired === null ||
        this.minMembersRequired === undefined
      ) {
        return false;
      }

      if (
        !Number.isInteger(this.minMembersRequired) ||
        (this.isCustodyOrg && this.minMembersRequired < MinimumRequiredInitiators.MIN_INITIATORS_REQUIRED_CUSTODY) ||
        this.minMembersRequired % 1 !== 0 ||
        this.minMembersRequired < 1 ||
        this.minMembersRequired > this.team.teamMembers.length
      ) {
        return false;
      }

      return !isNaN(this.minMembersRequired);
    }
    return false;
  }
}

export class MPCWalletData {
  constructor(
    public initiators?: MPCTeamConfig,
    public signers?: MPCTeamConfig
  ) {}
}

export class WalletRequest {
  constructor(
    public name: string,
    public type: "mpc" | "multisig_shield" | undefined,
    public chain: any,
    public signerTeamId?: number,
    public initiatorTeamId?: number,
    public initiatorQuorum?: number,
    public signerQuorum?: number,
    public subOrgSigners?: number[]
  ) {}
}
export enum WalletSuspendStatus {
  UN_SUSPENDED = 0,
  SUSPENDED = 1,
}
export interface GasstationRule {
  id: number;
  gasstationWalletId: number;
  protocol: string;
  orgid: number;
  transfer_amount: string;
  min_amount: string;
  ruleType: string;
  max_fees: string;
  sprayerWalletId: number;
  destinitionWalletId: number;
  status: number;
  gasStationWalletDetails: Wallet;
  DestnitionWalletDetails: Wallet;
  sprayerWalletDetails: Wallet;
  condition: string;
}

/**
 * Represents the version of a wallet.
 */
export enum WalletVersion {
  V1 = 1,
  V2 = 2,
}


/**
 * Represents the user type for a wallet.
 */
export enum WalletUserType {
  INITIATOR = "INITIATOR",
  SIGNER = "SIGNER",
  VIEWER = "VIEWER",
  READ = 'READ',
}

export interface IScheduledBatch {
  id: number;
  walletid: number;
  address: string;
  amount: string;
  recurringtime: string;
  transactioncount: number;
  startdate: string;
  enddate: string;
  startnonce: number;
  balancetrigger: string;
  lasttransaction: string;
  currentbalance: string;
  asset: string;
  status: StatusENUM;
  chain: string;
  currentnonce: number;
  lastadminpushtime: string;
  walletNameInfo: string;
  coolDownInfo: number;
  remainingTxs: number;
  walletKeys: MultiSigWalletKeys[];
}
