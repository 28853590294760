<div *ngIf="authDetails" class="page-header"
  style="padding: 22px 30px;width: 100vw;position: sticky;top: 0;z-index: 8;background-color: #fff;box-shadow: 0 0 20px #dce2ee;">
  <div class="header-wrapper row m-0">
    <div class="col header-logo-wrapper pull-left">
      <div class="logo-wrapper"><a routerlink="/" href="/"><img src="assets/images/logo/logo.png" alt=""
            class="img-fluid for-light"></a></div>
    </div>
    <div class="nav-right pull-right right-header p-0 ml-auto">
      <ul class="nav-menus">
        <li class="profile-nav onhover-dropdown p-0">
          <div class="media profile-media"><img alt="" src="{{authDetails.photoURL}}" class="b-r-10">
            <div class="media-body" style="margin-left: 15px;"><span>{{authDetails.displayName}}</span>
              <p class="mb-0" style="font-size: 12px; line-height: 1; color: rgba(43, 43, 43, 0.7);" (click)="logout()">
                Logout</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

<div *ngIf="authDetails" class="page-body" style="min-height: calc(100vh - 80px);margin-top: 80px;padding: 0px 60px;">
  <div *ngIf="!authDetails.emailVerified">
    <div class="alert p-4"
      style="box-shadow: 0px 3px 6px rgba(142, 152, 149, 0.15);border-radius: 12px;border: 1px solid #89B7FF;background: white;">
      <strong>Verify your Email Address</strong> We have sent a verification email to
      <strong>{{authDetails.email}}</strong>. Please confirm to proceed.
      <button *ngIf="resend" type="submit" class="btn btn-link p-0 primary"
        style="text-decoration: underline;float: right;" (click)="resendEmail()">Resend Email</button>
      <button *ngIf="!resend" type="submit" class="btn btn-link p-0 disabled"
        style="text-decoration: underline;float: right;">Email Sent ({{timeLeft}})</button>
    </div>
  </div>

  <div class="card mb-0" style=" box-shadow: 0px 3px 6px rgb(142 152 149 / 15%); border-radius: 12px; ">
    <div class="card-body p-4">
      <div class="row no-gutters align-items-center">
        <div class="media col-8">
          <div class="media-body">
            <h4 class="text-black"><img src="../../../assets/images/onboarding/hand.svg"> Hello,
              <ng-container *ngIf="userDetails?.organizations.length; else unknown">
                {{userDetails.displayName}}
              </ng-container>
              <ng-template #unknown>
                Friend
              </ng-template>
            </h4>
            <div class="onBoarding_Text">You can start using Vaults in a few simple steps. If you need any help, do not
              hesitate to reach out to us. For 1-on-1 onboarding support, you can schedule a call by clicking on the
              given link. Please set up your account before scheduling a call.</div>
          </div>
        </div>
        <div class="col-4 text-right">
          <div class="mt-2"><a href="https://calendly.com/d/dhv-7vm-f7v/liminal-sales-demo" target="_blank"
              class="btn btn-outline-primary px-3">
              <h6 class="text-primary mb-0"><img src="../../../assets/images/onboarding/calendar.svg"
                  class="pr-1"><span>Schedule Onboarding Call</span></h6>
            </a></div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="['unverified', 'new'].includes(basicState)">
    <div class="card signup-wrapper mt-4" [ngClass]="authDetails.emailVerified? '' : 'disabled'">

      <div [ngClass]="success?'show':'hide'" class="success-tick">
        <div class="swal2-icon swal2-success" [ngClass]="success?'swal2-icon-show':''"
          style="display: flex;top: 50%;left: 50%;transform: translate(-50%, -50%);position: absolute;">
          <div class="swal2-success-circular-line-left" style="background-color: #ffffffde;"></div> <span
            class="swal2-success-line-tip"></span> <span class="swal2-success-line-long"></span>
          <div class="swal2-success-ring"></div>
          <div class="swal2-success-fix" style="background-color: #ffffffde;"></div>
          <div class="swal2-success-circular-line-right" style="background-color: #ffffffde;"></div>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-xl-6 col-md-6">
          <div class="d-flex flex-column align-items-center mr-md-5 pr-md-4">
            <img class="img-fluid round-top-img" src="../../../assets/images/auth/top-round-icon.svg" alt="icon" />
            <img class="img-fluid" src="../../../assets/images/auth/auth-img.svg" alt="img" style="max-height: 100%;" />
          </div>
        </div>
        <div class="col-xl-6 col-md-6 pl-md-0">
          <div class="top-bg-dot">
            <img src="../../../assets/images/auth/bg-top.svg" alt="img" />
          </div>
          <div class="form-wrapper">
            <h6 class="font-weight-bold">Account Setup</h6>
            <p>Start your onboarding process by adding some of your details. This is required to complete your profile.
            </p>
            <div *ngIf="orgState == 'create_organization'">
              <div class="form-group">
                <input #verifyName="ngModel" [(ngModel)]="fullName" pattern="[a-zA-Z0-9. ]*" type="text"
                  class="form-control custom-form-control" placeholder="Full Name">
              </div>
              <div class="form-group">
                <input #verifyAccountName="ngModel" [(ngModel)]="accountName" pattern="[a-zA-Z0-9. ]*" type="text"
                  class="form-control custom-form-control" placeholder="Account Name">
              </div>
              <button class="btn btn-primary-new w-100 mt-4" (click)="addOganization()"
                *ngIf="!loading && fullName && accountName && !verifyName.invalid && !verifyAccountName.invalid">Continue</button>
              <button class="btn btn-primary-new w-100 mt-4" disabled
                *ngIf="loading || !fullName || !accountName || verifyName.invalid || verifyAccountName.invalid">Continue</button>
            </div>

            <div *ngIf="orgState == 'join_organization'">
              <div class="form-group">
                <input #verifyName="ngModel" [(ngModel)]="fullName" pattern="[a-zA-Z0-9. ]*" type="text"
                  class="form-control custom-form-control" placeholder="Full Name">
              </div>
              <div>
                <p>Select the organisation from the invited list</p>
                <div *ngFor="let pentx of userPendingOrgReq">
                  <input [(ngModel)]="organization" type="radio" class="d-none" value="{{pentx.request.id}}" name="organization" id="{{pentx.orgdeatails.name}}" autocomplete="off">
                  <label class="btn btn-success text-left w-100 p-3" for="{{pentx.orgdeatails.name}}">{{pentx.orgdeatails.name}}</label>
                </div>
              </div>

              <button class="btn btn-primary-new w-100 mt-4" (click)="joinOganization()"
                *ngIf="!loading && fullName && organization && !verifyName.invalid">Continue</button>
              <button class="btn btn-primary-new w-100 mt-4" disabled
                *ngIf="loading || !fullName || !organization || verifyName.invalid">Continue</button>
            </div>
          </div>
        </div>
      </div>

      <div class="bottom-bg-dot">
        <img src="../../../assets/images/auth/bg-bottom.svg" alt="img" />
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="basicState == 'onboarding' && userType == 2">
    <div class="onboarding row justify-content-md-center mt-4">

      <div class="col-4 d-flex">
        <div class="card" [ngClass]="members>1?'success':''">
          <img *ngIf="members>1" class="position-absolute" style="right: 0;"
            src="../../../assets/images/onboarding/icon-tick.svg">
          <div class="card-body d-flex flex-column justify-content-between p-3">
            <div>
              <div class="text-center">
                <img class="p-4" src="../../../assets/images/onboarding/invite-members.svg">
              </div>
              <div class="text-center">
                <h6 class="onBoarding_Sub_Title">Invite Members</h6>
              </div>
              <div class="onBoarding_Text text-center">
                Liminal group wallet provides utmost safety by adding more than one member to sign the transactions. If
                one member loses the key, funds can be easily retrieved using another member's key.
              </div>
            </div>
            <div class="text-center py-3 w-75 ml-auto mr-auto">
              <app-invite-members [invite_component_for]="'invite_members'"></app-invite-members>
            </div>
          </div>
        </div>
      </div>

      <div class="col-4 d-flex">
        <div class="card" [ngClass]="keys?'success':''">
          <img *ngIf="keys" class="position-absolute" style="right: 0;"
            src="../../../assets/images/onboarding/icon-tick.svg">
          <div class="card-body d-flex flex-column justify-content-between p-3">
            <div>
              <div class="text-center">
                <img class="p-4" src="../../../assets/images/onboarding/link-device.svg">
              </div>
              <div class="text-center">
                <h6 class="onBoarding_Sub_Title">Link Device</h6>
              </div>
              <div class="onBoarding_Text text-center">
                Link your hardware device to your Liminal account. This will help you create wallets, and sign the
                transactions seamlessly.
              </div>
            </div>
            <div class="text-center py-3 w-75 ml-auto mr-auto">
              <app-my-account [link_component_for]="'link_onbaoard'"></app-my-account>
            </div>
          </div>
        </div>
      </div>

      <div class="col-4 d-flex">
        <h6 *ngIf="userType!=2" class="message p-5"
          style="position: absolute; z-index: 999; left: 0; top: 0; bottom: 0; display: flex; align-items: center; justify-content: center; text-align: center;">
          You don't have sufficient permissions yet to create a wallet. Ask the admin to add a wallet and add you as a
          member!</h6>
        <div class="card" [ngClass]="keys && members > 1 && userType == 2?'':'disabled'">
          <div class="card-body d-flex flex-column justify-content-between p-3">
            <div>
              <div class="text-center">
                <img class="p-4" src="../../../assets/images/onboarding/create-wallet.svg">
              </div>
              <div class="text-center">
                <h6 class="onBoarding_Sub_Title">Create Cold Wallet</h6>
              </div>
              <div class="onBoarding_Text text-center">
                Create the Liminal wallet in just two simple steps. Follow the instructions and secure your digital
                assets in a simple and safer group wallet.
              </div>
            </div>

            <div class="text-center py-3 w-75 ml-auto mr-auto">
              <app-new-group-wallet [component_for]="'onboard'" class="f_size"></app-new-group-wallet>
            </div>
          </div>
        </div>
      </div>

    </div>
  </ng-container>
</div>

<app-trezor-webusb></app-trezor-webusb>