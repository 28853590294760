import { Pipe, PipeTransform } from '@angular/core';
import { getName } from '../../helpers/coins';
import { StatusENUM } from '../../entities/misc';
import { Rule } from '../../entities/firewall.model';
@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  transform(value: any, searchTerms: any): unknown {
    if (!searchTerms) {
      return value;
    }
    return value.filter(function (search) {
      return search?.displayName?.toLowerCase().indexOf(searchTerms?.toLowerCase()) > -1
    });
  }
}
@Pipe({
  name: 'search_coin'
})
export class SearchCoinPipe implements PipeTransform {
  transform(value: any, searchTerms: any , searchKey?:string): unknown {
    if (!searchTerms) {
      return value;
    }
    return value.filter(function (search) {
      return getName(search?.key || search[searchKey])?.toLowerCase()?.indexOf(searchTerms?.toLowerCase()) > -1
    });
  }
}
@Pipe({
  name: 'wallet_search'
})
export class SearchWalletPipe implements PipeTransform {
  transform(wallets: any, searchTerms: any): unknown {
    if (!searchTerms) {
      return wallets;
    }
    searchTerms = searchTerms?.toLowerCase();
    return wallets.filter(item => {
      if (item?.coinfullname && item?.coinfullname?.toLowerCase()?.indexOf(searchTerms) !== -1) {
          return true;
      }
      if (item?.name && item?.name?.toLowerCase()?.indexOf(searchTerms) !== -1) {
          return true
      }
      if(item?.coin && item?.coin?.toLowerCase()?.indexOf(searchTerms) !== -1) {
          return true
      }
      return false;
    });
  }
}

@Pipe({
  name: 'search_exchange'
})
export class SearchExchangePipe implements PipeTransform {
  transform(exchanges: any, searchTerm: any): Array<any> {
    if (!searchTerm) {
      return exchanges;
    }
    return exchanges.filter((el)=> el.name.toLowerCase().indexOf(searchTerm) > -1)
  }
}

@Pipe({
  name: 'policyListSearch'
})
export class SearchPolicyListPipe implements PipeTransform {
  transform(policyList: any, searchTerms: any): unknown {
    if (!searchTerms) {
      return policyList;
    }
    // console.log('policyList', policyList);
    searchTerms = searchTerms?.toLowerCase();
    return policyList?.filter(item => item?.walletname?.toLowerCase()?.includes(searchTerms) || item?.name?.toLowerCase()?.includes(searchTerms) || item?.subtype?.toLowerCase()?.includes(searchTerms) || item?.condition?.toLowerCase()?.includes(searchTerms) || item?.coinfullname?.toLowerCase()?.includes(searchTerms) || item?.policytypecustom?.toLowerCase()?.includes(searchTerms));
  }
}
@Pipe({
  name: 'walletListSearch'
})
export class SearchWalletListPipe implements PipeTransform {
  transform(walletList: any, searchTerms: any): unknown {
    if (!searchTerms) {
      return walletList;
    }
    searchTerms = searchTerms?.toLowerCase();
    return walletList?.filter(item => item?.name?.toLowerCase()?.includes(searchTerms) || item?.coinfullname?.toLowerCase()?.includes(searchTerms) || item?.config?.toLowerCase()?.includes(searchTerms) || item?.assets[0]?.coin?.toLowerCase()?.includes(searchTerms));
  }
}

@Pipe({
  name: 'rulesListSearch'
})
export class SearchRulesListPipe implements PipeTransform {
  transform(rulesList: any, searchTerms: any): unknown {
    if (!searchTerms) {
      return rulesList;
    }
    return rulesList.filter(item => item?.sprayerWalletDetails?.name?.toLowerCase()?.includes(searchTerms?.toLowerCase()));
  }
}

@Pipe({
  name: 'walletAddressSearch'
})
export class SearchWalletAddressPipe implements PipeTransform {
  transform(walletAddress: any, searchTerms: any): unknown {
    if (!searchTerms) {
      return walletAddress;
    }
    searchTerms = searchTerms?.toLowerCase();
    return walletAddress?.filter(item => item?.name?.toLowerCase()?.includes(searchTerms) || item?.condition?.toLowerCase()?.includes(searchTerms));
  }
}

@Pipe({
  name: 'historysearch'
})
export class historySearchPipe implements PipeTransform {
  transform(txs: any, searchTerms: any): unknown {
    if (!searchTerms) {
      return txs;
    }
    searchTerms = searchTerms?.toLowerCase();
    return txs?.filter(item => item?.wallet?.name?.toLowerCase()?.includes(searchTerms) || item?.coin?.toLowerCase()?.includes(searchTerms) || item?.type?.toLowerCase()?.includes(searchTerms) || item?.wallet?.address?.toLowerCase()?.includes(searchTerms) || item?.coinfullname?.toLowerCase()?.includes(searchTerms) || item?.txid?.toLowerCase()?.includes(searchTerms) || item?.externaladdress?.toLowerCase()?.includes(searchTerms));
  }
}

@Pipe({
  name: 'fromToDate'
})
export class fromToDatePipe implements PipeTransform {
  transform(txs: any, fromDate: any, toDate: any, _txs: any): unknown {
    if (fromDate || toDate) {
      return _txs;
    } else {
      return txs;
    }
  }
}

@Pipe({
  name: 'consolidationRule'
})
export class consolidationRuleSearchPipe implements PipeTransform {
  transform(data: any, searchTerms: any): unknown {
    if (!searchTerms) {
      return data;
    }
    searchTerms = searchTerms?.toLowerCase();
    return data?.filter(item => item?.walletname?.toLowerCase()?.includes(searchTerms) || item?.chain?.toLowerCase()?.includes(searchTerms) || item?.asset?.toLowerCase()?.includes(searchTerms) || item?.ConsolidateDestinationAddress?.toLowerCase()?.includes(searchTerms));
  }
}

@Pipe({
  name: 'teamListSearch'
})
export class teamsListSearchPipe implements PipeTransform {
  transform(data: any, searchTerms: any): unknown {
    if (!searchTerms) {
      return data;
    }
    return data?.filter(item => item?.name?.toLowerCase()?.includes(searchTerms?.toLowerCase()));
  }
}

@Pipe({
  name: 'usersListSearch'
})
export class usersListSearchPipe implements PipeTransform {

  filterDevice(ele, searchTerms) {
    if (ele?.providers) {
      let result = Object?.values(ele?.providers)?.find((providerKeys: string) => {
        if (providerKeys?.includes(searchTerms?.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      })
      if (result) {
        return ele
      }
    }
  }

  transform(data: any, searchTerms: any): unknown {
    if (!searchTerms) {
      return data;
    }
    searchTerms = searchTerms?.toLowerCase();
    return data?.filter(item => item?.displayName?.toLowerCase()?.includes(searchTerms) || item?.email?.toLowerCase()?.includes(searchTerms) || item?._userType?.toLowerCase()?.includes(searchTerms) || item?._userStatus?.toLowerCase()?.includes(searchTerms) || item?._userStatus?.toLowerCase()?.includes(searchTerms) || this.filterDevice(item, searchTerms));
  }
}

@Pipe({
  name: 'defaultImage'
})
export class defaultImagePipe implements PipeTransform {
  transform(data: any): unknown {

    if (!data) {
      return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALISURBVHgBxZjtbtJgFMf/7ToQcQYkMieLqR9MTPzgvAIxXoC7BHYF0ysQrgC9Arc7mDegGP3uYlyiX0yjmS9IV2ZCJlsLntMWXLuWPk8Z2y85PKPPYfz3nJeeTkEKLMsqOI5TpR8LiqLofG04HBpsmqYZxWLRgCSKqCN/OS3rZKtkKwnuBlmLrCEqKlEICdBpeUpWQzo2RAQpCSL4BOpkBUxPncQ0ICuERDRpeYzTZYtsjQR1ISKERLxA+lAksU32ICxGxUkR0+SDCJzozfDFgJBOp1ODlxOzpmaaZiDs49D41fGaTIcgtjOAaVq0Ori8kMfCpTwk4NDcG1WTdmxjXUbEr7aJr7s/xu+//wQWr5ZwY3lJ9FdwJXIarPEb90T8ZvVeVEj/8Agfdj5H7l2/VkZlqQwJipy4bo5Qu16F1Gl0YvfMPQsyDAYDN1dcIXNzc49kPuxQbsRhT9iLQlXV+2MhRBUSZDPzsXsXcxcgSZVTQ/WrRaqFL5ZLyMSIkcyPEbpq27YOSSiUuH3rZqBc+STC10ShHF3RkJJsJuN+MfcQzplJ4RJBWsjRwMaX3m93DXDgLfOqhuXcFeS1LGSggUrrijpbhz28au+cFBHi4/43PCzfQSEjFiYKtdtHDAjyrvMpUQTDPm/JVwJD9W/HRpLn7sEeenYforBvu78v5Esatt0+Qt3tTZIzh0WW9t8/Im4tfnGFUHfbwPmx6WrgFzqaFrzb8lljwBsfA+XLg20z7hMVKklZKrlSksvmaGQMzKzU7nkUSHpmOS0MeIORKyQ8s/KQclYhCkzzASFcRvBCNGsafl4iUogv5tmMxbCIevjipAcsnpxikzclT/w/FMJCfDE6JCf7GAx4OdGKcxD6bwAJqsGbuHXIwcn4PCoUqYQcE1Sl20GNOvFdxJe5QT4vyWdr0glMJSRCmI7/p2SQdaMesEX4B69i/IcESSqNAAAAAElFTkSuQmCC'
    } else {
      return data;
    }

  }
}
@Pipe({
  name: 'pendingTxSearch'
})
export class pendingSearchPipe implements PipeTransform {
  transform(txs: any, searchTerms: any): unknown {
    if (!searchTerms) {
      return txs;
    }
    searchTerms = searchTerms?.toLowerCase();
    return txs.filter(item => item?.walletinfor?.name?.toLowerCase()?.includes(searchTerms) 
    || item?.asset?.toLowerCase()?.includes(searchTerms) 
    || getName(item?.asset)?.toLowerCase()?.includes(searchTerms)
    || item?.destinationAddress?.toLowerCase()?.includes(searchTerms) 
    || item?.amount?.toLowerCase()?.includes(searchTerms)
    || item?.condition?.toLowerCase()?.includes(searchTerms) 
    || item?.name?.toLowerCase()?.includes(searchTerms) 
    || item?.wallet?.name?.toLowerCase()?.includes(searchTerms));
  }
}

@Pipe({
  name: 'refillSearch'
})
export class refillSearchPipe implements PipeTransform {
  transform(txs: any, searchTerms: any): unknown {
    if (!searchTerms) {
      return txs;
    }
    searchTerms = searchTerms?.toLowerCase();
    return txs?.filter(item => getName(item?.coin)?.toLowerCase()?.includes(searchTerms) || item?.name?.toLowerCase()?.includes(searchTerms) || item?.coin?.toLowerCase()?.includes(searchTerms));
  }
}

@Pipe({
  name: 'wallet_search_staking'
})
export class SearchWalletStakingPipe implements PipeTransform {
  transform(wallets: any, searchTerms: any): unknown {
    if (!searchTerms) {
      return wallets;
    }
    return wallets.filter(item => {
      if (item?.wallet?.name?.toLowerCase()?.indexOf(searchTerms?.toLowerCase()) !== -1) {
          return item
        }
    });
  }
}

@Pipe({
  name: 'assets_search_staking'
})
export class SearchAssetsStakingPipe implements PipeTransform {
  transform(wallets: any, searchTerms: any): unknown {
    if (!searchTerms) {
      return wallets;
    }
    return wallets.filter(item => {
      if (item?.assets?.toLowerCase()?.indexOf(searchTerms?.toLowerCase()) !== -1) {
          return item
        }
    });
  }
}

@Pipe({
  name: 'protocol_search'
})
export class protocol_search implements PipeTransform {
  
  transform(data: any, searchTerms: any): unknown {

    if (!searchTerms) {
      return data;
    }
    return data.filter(item => item?.key?.toLowerCase()?.includes(searchTerms?.toLowerCase()));
  }
}

@Pipe({
  name: 'policyFilter'
})
export class PolicyFilterPipe implements PipeTransform {
  transform(data: Rule[], hide: 'send' | 'receive'): unknown {
    const list = data.filter((item) => {
      if (item.status === StatusENUM.INACTIVE) {
        return false;
      }
      if (hide) {
        return item.direction !== hide;
      }
      return true;
    });
    return list;
  }
}