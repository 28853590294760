import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError, shareReplay } from "rxjs/operators";
import { Wallet, IScheduledBatch } from "../entities/wallet";
import { AuthServiceJWT } from "./auth.service";
import { Transaction } from "../entities/Transaction";
import { TransactionRequest } from "../entities/TransactionRequest";
import { environment } from "src/environments/environment";
import { PubKeys } from "../entities/Pubkey";
import { Output, preBuildOutput } from "../entities/Output";

import { TransactionObject, TxParams, SignedTransaction } from "../entities";
import axios from "axios";
import { formatAddress } from "../helpers/gnosisHelper";
import { signedStakingTransaction } from "../helpers/trezor/stakingSign";
import { Router } from "@angular/router";
import { UserTypeEnum } from "../entities/User";
import { UserRoleEnum } from "@entities/members";
import { constructUrl } from "src/app/components/reports/audit-util";
@Injectable({
  providedIn: "root",
})
export class HttpService {
  accessToken;
  constructor(
    private httpClient: HttpClient,
    private authService: AuthServiceJWT,
    private router: Router
  ) { }
  //do not  change url here , change in settings file
  baseUrl: string = environment.apiUrl;
  private profile$: Observable<any>;

  getRate(coin: string): Observable<string> {
    return this.httpClient.get(this.baseUrl + "/public/rates/" + coin).pipe(
      map((data: string) => {
        return JSON.stringify(data);
      }),
      catchError((error) => {
        console.log(error);
        return throwError("Coin not found!");
      })
    );
  }
  getWalletById(walletId: string, type: string): Observable<string> {
    let url =
      type == "single"
        ? this.baseUrl + "/wallet/" + walletId
        : this.baseUrl + "/wallet/msig/" + walletId + "/details";
    return this.httpClient.get(url).pipe(
      map((data: string) => {
        return JSON.stringify(data);
      }),
      catchError((error) => {
        console.log(error);
        return throwError("Wallet not found!");
      })
    );
  }
  getAllAvailableSigners(): Observable<string> {
    return this.httpClient.get(this.baseUrl + "/users/all").pipe(
      map((data: string) => {
        return JSON.stringify(data);
      }),
      catchError((error) => {
        console.log(error);
        return throwError("Wallet not found!");
      })
    );
  }
  //  check here for the coin lower case
  saveKey(key: PubKeys, provider: string): Observable<string> {
    return this.httpClient
      .post(this.baseUrl + "/keys/add", {
        pubkey: {
          coin: key.coin,
          type: key.type,
          provider: provider,
          xpub: key.xpub,
          path: key.path,
        },
      })
      .pipe(
        map((data: string) => {
          return JSON.stringify(data);
        }),
        catchError((error) => {
          console.error(error);
          console.log(error);
          return throwError("Wallet not found!");
        })
      );
  }
  addNewMultisigWallet(
    walletName: string,
    m,
    coSigners: string[],
    type: string,
    chain: string,
    accessToken?: string
  ): Observable<Object> {
    let new_wallet_data = {
      wallet: {
        name: walletName,
        m: m,
        cosigners: coSigners,
        type: type,
        chain: chain,
      },
    };
    console.info(walletName + m + coSigners + type + chain);
    
    let headers: HttpHeaders = new HttpHeaders();
    if (accessToken) {
      headers = headers.append("Authorization", "Bearer " + accessToken);
    }

    return this.httpClient
      .post(this.baseUrl + "/wallet/add", {
        wallet: {
          name: walletName,
          m: m,
          cosigners: coSigners,
          type: type,
          chain: chain,
        },
      }, { headers })
      .pipe(
        map((data: string) => {
          return new_wallet_data;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }
  getAllMultisigWallets(): Observable<Wallet[]> {
    const user = JSON.parse(localStorage.getItem("userProfile"));
    // return of(dummyData.wallets) as unknown as Observable<Wallet[]>;
    return this.httpClient.get(this.baseUrl + "/v2/wallets/list").pipe(
      map((data: any) => {
        if (user?.type === 2) {
          return data.data;
        } else {
          return data.data.filter((ele) => ele.isArchived != 1);
        }
      }),
      catchError((error) => {
        console.log(error);
        try {
          if (
            error.error.message == "jwt expired" ||
            error.error.message == "jwt malformed" ||
            error.error.message == "secret or public key must be provided"
          ) {
            this.authService.SignOut();
          }
        } catch (error) { }
        return throwError(error);
      })
    );
  }

  getWalletPolicies(walletId: Number){
    console.log("calling thttp for getting policies");
    return this.httpClient.get(this.baseUrl + `/v2/policies/${walletId}`).pipe(
      map((resp: any) => {
        return resp.data;
      }));
    // return of(dummyData.policies[walletId.toString()]) as unknown as Observable<Policy[]>
  }

  getRefillWallets(): Observable<IScheduledBatch[]> {
    return this.httpClient.get(this.baseUrl + "/v2/refills/txns").pipe(
      map((resp: any) => {
        return resp.data;
      }));
    // return of(dummyData.scheduled) as unknown as Observable<IScheduledBatch[]>;
  }

  getAllWalletsForAdmin(): Observable<Wallet[]> {
    return this.httpClient.get(this.baseUrl + "/admin/wallets").pipe(
      map((data: any) => {
        return data.result;
      }),
      catchError((error) => {
        console.log(error);
        try {
          if (
            error.error.message == "jwt expired" ||
            error.error.message == "jwt malformed" ||
            error.error.message == "secret or public key must be provided"
          ) {
            this.authService.SignOut();
          }
        } catch (error) {
          console.log(error);
        }
        console.log(error);
        return throwError(error);
      })
    );
  }
  /**
   * @deprecated This method is deprecated. Please use a different method instead.
   * 
   * Retrieves all multisig wallet transactions based on the provided parameters.
   * 
   * @param from - The starting date for filtering transactions (optional).
   * @param to - The ending date for filtering transactions (optional).
   * @param walletid - The ID of the wallet for filtering transactions (optional).
   * @returns An Observable that emits an array of Transaction objects.
   */
  getAllMultisigWalletTransactions(
    from?: string,
    to?: string,
    walletid?: number
  ): Observable<Transaction[]> {
    const user = JSON.parse(localStorage.getItem("userProfile"));
    const datefilterapplied = (from != null && to != null);
    if (datefilterapplied && walletid) {
      var url = this.baseUrl + "/wallet/all/txs?walletid=" + walletid + "&from=" + from + "&to=" + to;
    }
    else if( datefilterapplied && !walletid ){
      url = this.baseUrl + "/wallet/all/txs?from=" + from + "&to=" + to;
    }
    else if (walletid != null) {
      url = this.baseUrl + "/wallet/all/txs?walletid=" + walletid;
    }
    else {
      url = this.baseUrl + "/wallet/all/txs";
    }
    return this.httpClient.get(url).pipe(
      map((data: any) => {
        if (user?.type === UserTypeEnum.ADMIN) {
          return data.result;
        } else {
          return data.result.filter((ele) => ele.wallet.isArchived != 1);
        }
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }
  getAllPendingWalletTransactions(): Observable<any> {
    return this.httpClient.get(this.baseUrl + "/wallet/tx/pending").pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        console.log(error);
        return throwError("Failed to get transaction list!");
      })
    );
  }
  getAllScheduledTransactions(): Observable<any> {
    return this.httpClient.get(this.baseUrl + "/wallet/tx/scheduled").pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        console.log(error);
        return throwError("Failed to get transaction list!");
      })
    );
  }
  getPendingWalletTransactions(walletId: string): Observable<string> {
    return this.httpClient
      .get(this.baseUrl + "/wallet/msig/" + walletId + "/tx/pending")
      .pipe(
        map((data: string) => {
          return JSON.stringify(data);
        }),
        catchError((error) => {
          console.log(error);
          return throwError("Wallet not found!");
        })
      );
  }
  getMultisigWalletTx(
    walletId: string,
    address: string,
    amount: string
  ): Observable<string> {
    return this.httpClient
      .get(
        this.baseUrl +
        "/wallet/msig/" +
        walletId +
        "/tx?address=" +
        address +
        "&amount=" +
        amount
      )
      .pipe(
        map((data: string) => {
          return JSON.stringify(data);
        }),
        catchError((error) => {
          console.log(error);
          return throwError("Ooops something went wrong!");
        })
      );
  }
  buildMultisigShieldWalletTx(
    walletId: string,
    address: string,
    amount: string
  ): Observable<any> {
    return this.httpClient
      .get(
        this.baseUrl +
        "/wallet/" +
        walletId +
        "/transfer/create?address=" +
        address +
        "&amount=" +
        amount
      )
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error.error.message);
        })
      );
  }
  addNewMultisigTransaction(
    walletId: string,
    txreq: TransactionRequest
  ): Observable<any> {
    console.info("Transaction Request:", txreq);

    return this.httpClient
      .post(this.baseUrl + "/wallet/msig/" + walletId + "/tx", {
        txreq: txreq,
      })
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error) => {
          console.log(error);
          return throwError("Could not add Tx!");
        })
      );
  }
  getDotCancleTransaction(
    walletId: string,
    txreq: TransactionRequest
  ): Observable<any> {
    return this.httpClient.get(
      this.baseUrl +
      "/wallet/txReqId/" +
      txreq.id +
      "/walletid/" +
      walletId +
      "/reject".toString()
    );
  }
  postDotCancleTransaction(
    walletId: string,
    txreq: TransactionRequest,
    bodyContent
  ): Observable<any> {
    return this.httpClient.post(
      this.baseUrl +
      "/wallet/txReqId/" +
      txreq.id +
      "/walletid/" +
      walletId +
      "/reject".toString(),
      bodyContent
    );
  }
  getGnosis(walletId: string, chainname: string): Observable<any> {
    return this.httpClient
      .get(environment.evm_config[chainname].gnosis_api + `/safes/${walletId}`)
      .pipe(
        map((data: any) => {
          console.info(data);
          return data;
        }),
        catchError((error) => {
          console.error("Wallet not found!", error);
          return throwError("Wallet not found!");
        })
      );
  }
  broadcastGnosisTx(walletId: string, txParam: any, chainname: string) {
    let url;
    if (chainname === "XINFIN") {
      txParam.to = formatAddress(txParam.to, "0x", chainname);
      url =
        environment.evm_config[chainname].gnosis_api +
        "/safes/" +
        `${walletId}/multisig-transactions`;
    } else {
      url =
        environment.evm_config[chainname].gnosis_api +
        "/transactions/" +
        `${walletId}/propose`;
    }
    return this.httpClient.post(url, txParam).pipe(
      map((data: string) => {
        return JSON.stringify(data);
      }),
      catchError((error) => {
        console.error("broadcastGnosisTx", error);
        return throwError(JSON.stringify(error.error));
      })
    );
  }
  broadcastGnosisTxSafeRelay(
    walletId: string,
    txParam: any,
    chainname: string
  ) {
    return this.httpClient
      .post(
        `https://safe-relay.gnosis.io/api/v1/safes/${walletId}/transactions/`,
        txParam
      )
      .pipe(
        map((data: string) => {
          return JSON.stringify(data);
        }),
        catchError((error) => {
          console.error("broadcastGnosisTx", error);
          return throwError(JSON.stringify(error.error));
        })
      );
  }
  getGnosisGasEstimation(txParam: any, toAdd: string): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("NO-HEADERS", "true");
    return this.httpClient
      .post(
        `${environment.getsafetxgasapi}/api/v2/safes/${toAdd}/transactions/estimate/`,
        txParam,
        { headers }
      )
      .pipe(
        map((data: string) => {
          return JSON.stringify(data);
        }),
        catchError((error) => {
          console.error("getGnosisGasEstimation", error);
          return throwError(JSON.stringify(error.error));
        })
      );
  }
  broadcastTx(txHex: string) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("NO-HEADERS", "true");
    return this.httpClient
      .post("https://tbtc1.trezor.io/api/v1/sendtx", txHex, { headers })
      .pipe(
        map((data: string) => {
          return JSON.stringify(data);
        }),
        catchError((error) => {
          console.error("Ooops something went wrong!", error);
          return throwError("Ooops something went wrong!");
        })
      );
  }
  //Add wallet policy
  addwalletPolicy(walletid, label, address) {
    let policyobj = { policy: { label: label, address: address } };

    return this.httpClient.post(
      this.baseUrl + "/admin/" + walletid + "/policy/add".toString(),
      policyobj
    );
  }
  refillPush(walletid, coin): Observable<any> {
    return this.httpClient.post(
      this.baseUrl + "/admin/" + walletid + "/refillpush".toString(),
      {
        coin: coin,
      }
    );
  }
  async getPolicyForWallet(walletid) {
    return this.httpClient.get(
      this.baseUrl + "/admin/" + walletid + "/policy".toString()
    );
  }
  async getWalletInformationForAdmin(walletid) {
    return this.httpClient.get(
      this.baseUrl + "/admin/" + walletid + "/walletinfo".toString()
    );
  }
  async getUserInformationForAdmin(walletid) {
    return this.httpClient.get(
      this.baseUrl + "/admin/" + walletid + "/userinfo".toString()
    );
  }

  /**
   * Fetches the user profile deatils from the server.
   * @param isHardReload A flag indicating whether to force a hard reload of the profile data.
   * If true, the profile data will be re-fetched from the server even if it's already cached.
   * @returns An Observable emitting the user profile data.
   */
  getprofile(isHardReload?: boolean): Observable<any> {
    if (isHardReload || !this.profile$) {
      this.profile$ = this.httpClient.get(this.baseUrl + "/account/me").pipe(
        shareReplay(1)
      );
    }
    return this.profile$;
  }

  SyncwithCourier() {
    return this.httpClient.get(this.baseUrl + "/admin/couriersync");
  }
  getOrgMembers(orgId): Promise<any> {
    return this.httpClient
      .get(this.baseUrl + "/org/orgid/" + orgId + "/members")
      .toPromise();
  }
  $OrgMembers(orgId): Observable<any> {
    return this.httpClient
      .get(this.baseUrl + "/org/orgid/" + orgId + "/members");
  }
  getAllMembers(): Observable<any> {
    return this.httpClient.get(this.baseUrl + "/org/all").pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        console.log(error);
        try {
          if (
            error.error.message == "jwt expired" ||
            error.error.message == "jwt malformed" ||
            error.error.message == "secret or public key must be provided"
          ) {
            this.authService.SignOut();
          }
        } catch (error) {
          console.log(error);
        }
        console.log(error);
        return throwError(error);
      })
    );
  }
  async getXRPAddressInformation(address: string) {
    return this.httpClient
      .post(this.baseUrl + "/xrp/accountinfo/", { address: address })
      .pipe(
        map((data: any) => {
          return data.info;
        }),
        catchError((error) => {
          console.log(error);

          return throwError("Wallet not found!");
        })
      );
  }

  async broadcastXRPTransaction(
    txParam: string,
    txJSON: string,
    output: Output
  ) {
    console.info(this.baseUrl + "/xrp/broadcasttransactionxrp/", {
      txParam: txParam,
      txJSON: txJSON,
      output: output,
    });
    return this.httpClient
      .post(this.baseUrl + "/xrp/broadcasttransactionxrp/", {
        txParam: txParam,
        txJSON: txJSON,
        output: output,
      })
      .pipe(
        map((data: any) => {
          console.info(data);
          return data;
        }),
        catchError((error) => {
          console.log(error);

          return throwError("Broadcast error!");
        })
      );
  }

  getTransactionParam(transactionObj: TransactionObject): Observable<TxParams> {
    let apiEndPoint: string = this.baseUrl + "/txParam/txparam";

    return this.httpClient.post(apiEndPoint, transactionObj).pipe(
      map((data: any) => {
        console.info(data);
        return data;
      }),
      catchError((error) => {
        console.log(error);
        return throwError(error?.error || "Could not add Tx!");
      })
    );
  }

  async getPrebuildTx(transactionObj: TransactionObject) {
    try {
      const wallet = transactionObj.reqobj.wallet;
      const output = transactionObj.reqobj.output;
      console.info("getPrebuildTx", wallet);
      console.info("getPrebuildTx", output);
      let apiEndPoint: string =
        this.baseUrl + "/wallet/" + wallet.id + "/preBuildTransaction";
      let outPuts = [];
      let output1: preBuildOutput;
      if (!output.data) {
        output1 = { address: output.address, valueUnitAmount: output.value };
      } else {
        output1 = {
          address: output.address,
          valueUnitAmount: output.value,
          data: output.data,
        };
      }
      outPuts.push(output1);

      let requestBodyParams = {
        outputs: outPuts,
        symbol: wallet.coin?.toUpperCase() ?? undefined,
        //symbol: "myToken",
        tokenAddress: wallet.identifier ?? undefined,
        tokenType: transactionObj.reqobj.tokenType ?? undefined,
        //tokenAddress: "TMYH6vEB7AY15qqsq9cAj5vYMYmRUq6SCE"
      };

      let result = await axios({
        method: "post",
        url: apiEndPoint,
        data: requestBodyParams,
        headers: {
          accept: "application/json",
          authorization: "Bearer " + this.authService.getToken,
        },
      });
      return result.data;
    } catch (error) {
      console.log("Error: ", error);
        throw new HttpErrorResponse({ error: error?.response?.data || error });
      }
  }

  async SendTransaction(walletid, signTransaction: SignedTransaction) {
    let apiEndPoint: string =
      this.baseUrl + "/wallet/" + walletid + "/sendTransaction";

    try {
      var respdatasendtx = await axios({
        method: "post",
        url: apiEndPoint,
        data: JSON.stringify(signTransaction),
        headers: {
          "content-type": "application/json",
          authorization: "Bearer " + this.authService.getToken,
        },
      });
      return respdatasendtx;
    } catch (err) {
      throw new HttpErrorResponse({ error: err.response.data });
    }
  }

  async sendStakingTransaction(
    walletId,
    validatorId,
    type,
    txObj: signedStakingTransaction
  ) {
    let apiEndPoint: string =
      this.baseUrl +
      "/staking/submit-transaction/" +
      walletId +
      "/" +
      type +
      "/" +
      validatorId;

    var respdatasendtx = await axios({
      method: "post",
      url: apiEndPoint,
      data: txObj,
      headers: {
        "content-type": "application/json",
        authorization: "Bearer " + this.authService.getToken,
      },
    });
    return respdatasendtx;
  }

  async ApproveTx(data: string, walletid, Txid) {
    let apiEndPoint: string =
      this.baseUrl + "/wallet/" + walletid + "/approvalTransaction/" + Txid;
    var respApprove = await axios({
      method: "post",
      url: apiEndPoint,
      data: data,
      headers: {
        "content-type": "application/json",
        authorization: "Bearer " + this.authService.getToken,
      },
    });
    return respApprove;
  }

  async GetapproveTxSol(data: string, walletid, Txid) {
    let apiEndPoint: string =
      this.baseUrl + "/wallet/" + walletid + "/getapprovetxsol/" + Txid;
    var respApprove = await axios({
      method: "post",
      url: apiEndPoint,
      data: data,
      headers: {
        "content-type": "application/json",
        authorization: "Bearer " + this.authService.getToken,
      },
    });
    return respApprove;
  }

  async getApproveTx(walletid, Txid): Promise<any> {
    let apiEndPoint: string =
      this.baseUrl + "/wallet/" + walletid + "/approvalTransaction/" + Txid;
    var respApprove = await axios({
      method: "get",
      url: apiEndPoint,
      headers: {
        "content-type": "application/json",
        authorization: "Bearer " + this.authService.getToken,
      },
    });
    return respApprove;
  }

  addPolicy(
    walletId: number,
    label: string,
    type: string,
    signature: string,
    message: string,
    pubKey: string,
    accessToken: any,
    memo:string | number,
  ): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();

    headers = headers.append("Authorization", "Bearer " + accessToken);
    console.info(walletId);
    console.info(pubKey);
    console.info(message);
    console.info(signature);
    console.info(label);
    console.info(type);
    console.info(this.baseUrl);
    console.info(headers);
    console.info(memo);
    return this.httpClient.post(
      this.baseUrl + "/admin/" + walletId + "/policies/add",
      {
        pubKey: pubKey,
        message: message,
        signature: signature,
        label: label,
        policyType: type,
        memo:memo
      },
      { headers: headers }
    );
  }

  approvePolicy(
    policyId: number,
    walletId: number,
    signature: string,
    message: string,
    pubKey: string,
    accessToken
  ): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Authorization", "Bearer " + accessToken);
    console.info(walletId);
    console.info(pubKey);
    console.info(message);
    console.info(signature);
    console.info(this.baseUrl);

    return this.httpClient.post(
      this.baseUrl + "/admin/" + walletId + "/policies/approve",
      {
        pubKey: pubKey,
        message: message,
        signature: signature,
        policyRequestId: policyId,
      },
      { headers: headers }
    );
  }
  rempovePolicy(
    policyId: number,
    walletId: number,
    signature: string,
    message: string,
    pubKey: string,
    accessToken
  ): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Accept", "application/json");
    headers = headers.append("Authorization", "Bearer " + accessToken);

    return this.httpClient.post(
      this.baseUrl + "/admin/" + walletId + "/policies/remove",
      {
        pubKey: pubKey,
        message: message,
        signature: signature,
        policyId: policyId,
      },
      { headers: headers }
    );
  }
  rejectPolicy(walletId, policyTxId): Observable<any> {
    return this.httpClient
      .post(this.baseUrl + "/admin/" + walletId + "/policies/reject", {
        policyRequestId: policyTxId,
      })
      .pipe(
        map((data: any) => {
          // console.log(data);
          return data;
        }),
        catchError((error) => {
          console.log(error);
          return throwError("Could not reject Policy");
        })
      );
  }
  getAllPendingPoliciesTransactions(): Observable<any> {
    return this.httpClient.get(this.baseUrl + "/admin/policies/pending").pipe(
      map((data: any) => {
        // console.log(data);
        return data;
      }),
      catchError((error) => {
        console.log(error);
        return throwError("Policy not found!");
      })
    );
  }

  // Resend Email Verification
  resendEmailVerification(data): Observable<any> {
    let orgobj = {
      "emailDetails": {
        "email": data.email,
        "userid": data.userid,
      },
      "iss": data.iss,
    };
    return this.httpClient.post(
      this.baseUrl + "/emailTest/verify/resend",
      orgobj
    );
  }

  //Add organization
  createOrganization(data): Observable<any> {
    let orgobj = { org: { businessName: data } };
    // let orgobj = { "org": { "username": data.name, "businessName": data.orgname } }
    return this.httpClient.post(this.baseUrl + "/org/add", orgobj);
  }

  //user pending red organization
  getAllPendingUserReq(): Observable<any> {
    return this.httpClient
      .post(this.baseUrl + "/org/pendingrequest/all", {})
      .pipe(
        map((data: any) => {
          // console.log(data);
          return data;
        }),
        catchError((error) => {
          console.log(error);
          return throwError("Policy not found!");
        })
      );
  }

  acceptDeclineOrg(id, status): Observable<any> {
    return this.httpClient.post(this.baseUrl + "/org/" + id + "/" + status, {});
  }

  inviteMembersOrg(body): Observable<any> {
    // let headers: HttpHeaders = new HttpHeaders();
    // let memberobj = { "org": { "members": member, "orgid": id } }
    // console.log('memberobj', memberobj);
    // headers = headers.append('Accept', 'application/json');
    // headers = headers.append(
    //   'Authorization',
    //   'Bearer ' + this.authService.getToken
    // );
    // return this.httpClient.post(
    //   this.baseUrl + "/org/sendinvite", memberobj,
    //   { headers: headers }
    // )

    return this.httpClient.post(this.baseUrl + "/org/sendinvite", body);
  }

  updateUserName(name: string): Observable<any> {
    return this.httpClient.post(
      this.baseUrl + "/users/update/uname/" + name,
      {}
    );
  }

  // getallmembers(orgId): Observable<any> {
  //   let headers: HttpHeaders = new HttpHeaders();
  //   headers = headers.append('Accept', 'application/json');
  //   headers = headers.append(
  //     'Authorization',
  //     'Bearer ' + this.authService.getToken
  //   );
  //   return this.httpClient.get(
  //     this.baseUrl + "/org/orgid/" + orgId + "/members",{ headers: headers }
  //   ).pipe(
  //     map((data: any) => {
  //       return data;
  //     }), catchError(error => {
  //       this.logger.error(error, error)
  //       return throwError('Could not reject Policy');
  //     })
  //   )
  // }

  updateOrganizationDetail(
    orgId: number,
    name: string,
    businessname: string,
    address: string
  ): Observable<any> {
    return this.httpClient.post(this.baseUrl + "/org/update/" + orgId, {
      org: {
        businessName: businessname,
        name: name,
        address: address,
      },
    });
  }

  auth0ChangePassword(clientId, email, clientDomain): Observable<any> {
    return this.httpClient.post(
      "https://" + clientDomain + "/dbconnections/change_password",
      {
        client_id: clientId,
        email: email,
        connection: "Username-Password-Authentication",
      }
    );
  }

  auth0disableMFA(): Observable<any> {
    return this.httpClient.post(this.baseUrl + "/MFA/disable/all", {});
  }

  getOrganization(orgId: number): Observable<any> {
    return this.httpClient.get(this.baseUrl + "/org/" + orgId).pipe(
      map((data: any) => {
        // console.log(data);
        return data;
      }),
      catchError((error) => {
        console.log(error);
        return throwError("Organization not found!");
      })
    );
  }

  checkAml(address, chain, walletID): Observable<any> {
    return this.httpClient
      .post(this.baseUrl + "/aml/addressscreening", {
        toAddress: address,
        wallet: {
          chain: chain,
          id: walletID
        },
      })
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error) => {
          console.log(error);
          return throwError("Invalid request!");
        })
      );
  }

  addStaking(id, amount, ass_address, comment): Observable<any> {
    let dataobj = { amount: amount, comment: comment };
    console.info("dataobj", dataobj);

    return this.httpClient.post(
      this.baseUrl + "/admin/stake/" + id + "/delegate/" + ass_address,
      dataobj
    );
  }

  unStaking(id, amount, address, comment): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    let dataobj = { amount: amount, comment: comment };
    console.info("unstakeobj", dataobj);
    headers = headers.append("Accept", "application/json");
    headers = headers.append(
      "Authorization",
      "Bearer " + this.authService.getToken
    );
    return this.httpClient.post(
      this.baseUrl + "/admin/stake/" + id + "/unstake/" + address,
      dataobj
    );
  }

  stakingInfo(id): Observable<any> {
    return this.httpClient.post(
      this.baseUrl + "/admin/stake/" + id + "/stakeinfo",
      {}
    );
  }

  getStakingWalletList(): Observable<any> {
    return this.httpClient.post(this.baseUrl + "/admin/stake/wallets", {});
  }

  _getStakingWalletList(): Observable<any> {
    return this.httpClient.get(this.baseUrl + "/staking/wallets");
  }

  getStakeWalletInfo(walletId): Observable<any> {
    return this.httpClient.get(this.baseUrl + "/staking/walletinfo/" + walletId);
  }

  stakingPrebuild(
    walletId,
    comment,
    validatorId,
    type,
    obj,
    accessToken?
  ): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    if (accessToken) {
      headers = headers.append("Authorization", "Bearer " + accessToken);
    }
    return this.httpClient.post(
      this.baseUrl +
      "/staking/prebuild-transaction/" +
      walletId +
      "/" +
      type +
      "/" +
      validatorId,
      obj,
      { headers: headers }
    );
  }

  enableStaking(id, address, comment): Observable<any> {
    let dataobj = { comment: comment };
    console.info(dataobj);

    return this.httpClient.post(
      this.baseUrl + "/admin/stake/enable/" + id + "/" + address,
      dataobj
    );
  }

  withdrawStaking(id, nonce, address, comment): Observable<any> {
    let dataobj = { nonce: nonce, comment: comment };
    console.info(dataobj);
    return this.httpClient.post(
      this.baseUrl + "/admin/stake/" + id + "/claimstake/" + address,
      dataobj
    );
  }

  claimRewardStaking(id, amount, address, comment): Observable<any> {
    let dataobj = { amount: amount, comment: comment };
    console.info(dataobj);
    return this.httpClient.post(
      this.baseUrl + "/admin/stake/" + id + "/claimstake/" + address,
      dataobj
    );
  }

  enableStaking_v2(walletId, validatorId, accessToken): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Authorization", "Bearer " + accessToken);
    return this.httpClient.post(
      this.baseUrl + "/staking/enable-staking/" + walletId + "/" + validatorId,
      {},
      { headers: headers }
    );
  }

  explainTransaction(tx, chain): Observable<Wallet[]> {
    return this.httpClient
      .post(this.baseUrl + "/public/explaintransaction", {
        tx: tx,
        chain: chain,
      })
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error) => {
          console.log(error);
          try {
            if (
              error.error.message == "jwt expired" ||
              error.error.message == "jwt malformed" ||
              error.error.message == "secret or public key must be provided"
            ) {
              this.authService.SignOut();
            }
          } catch (error) { }
          return throwError(error);
        })
      );
  }

  generate_api_key(dataobj): Observable<Object> {
    return this.httpClient
      .post(this.baseUrl + "/admin/createDeveloperKeys", dataobj)
      .pipe(
        map((data: string) => {
          return data;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }

  view_api_details(): Observable<Object> {
    return this.httpClient.get(this.baseUrl + "/admin/getDeveloperKeys").pipe(
      map((data: string) => {
        return data;
      }),
      catchError((error) => {
        console.log(error);
        return throwError("API data not found!");
      })
    );
  }

  addConsolidationRule(id, bodyObj): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Accept", "application/json");
    let dataobj = bodyObj;
    console.info(dataobj);

    headers = headers.append(
      "Authorization",
      "Bearer " + this.authService.getToken
    );
    // return this.httpClient.post(
    //   this.baseUrl + "/wallet/" + id + "/addWalletSettings", dataobj,
    //   { headers: headers }
    // )
    return this.httpClient.post(
      this.baseUrl + "/wallet/" + id + "/addConsolidationRule",
      dataobj,
      { headers: headers }
    );
  }

  getOrgConsolidationRules(): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Accept", "application/json");
    headers = headers.append(
      "Authorization",
      "Bearer " + this.authService.getToken
    );
    return this.httpClient.get(
      this.baseUrl + "/wallet/all/getOrgConsolidationRules?status=active",
      { headers: headers }
    );
  }

  removeConsolidationRule(id, ruleId): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Accept", "application/json");
    let dataObj = { ruleId: ruleId };
    headers = headers.append(
      "Authorization",
      "Bearer " + this.authService.getToken
    );
    // return this.httpClient.post(
    //   this.baseUrl + "/wallet/" + id + "/removeWalletSettings",dataObj,
    //   { headers: headers }
    // )
    return this.httpClient.post(
      this.baseUrl + "/wallet/disableConsolidationRule",
      dataObj,
      { headers: headers }
    );
  }

  updateConsolidationRule(id, rule): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Accept", "application/json");
    let dataObj = { ruleId: id, rule: rule };
    headers = headers.append(
      "Authorization",
      "Bearer " + this.authService.getToken
    );
    return this.httpClient.put(
      this.baseUrl + "/wallet/updateConsolidationRule",
      dataObj,
      { headers: headers }
    );
  }

  updateConsolidationThreshold(id, thresholdID, rule): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Accept", "application/json");
    let dataObj = {
      ruleId: id,
      thresholdId: thresholdID,
      threshold_rule: rule,
    };
    headers = headers.append(
      "Authorization",
      "Bearer " + this.authService.getToken
    );
    return this.httpClient.put(
      this.baseUrl + "/wallet/updateConsolidationThreshold",
      dataObj,
      { headers: headers }
    );
  }

  addConsolidationThreshold(walletID, ruleID, thresholds): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Accept", "application/json");
    let dataObj = {
      ruleId: ruleID,
      thresholds: thresholds,
    };
    headers = headers.append(
      "Authorization",
      "Bearer " + this.authService.getToken
    );
    return this.httpClient.post(
      this.baseUrl + "/wallet/addConsolidationThreshold",
      dataObj,
      { headers: headers }
    );
  }

  getGasStationRules(): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + this.authService.getToken
    );
    return this.httpClient.get(this.baseUrl + "/admin/gasstationrule/all", {
      headers: headers,
    });
  }

  getGasStationWallets(): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Accept", "application/json");
    headers = headers.append(
      "Authorization",
      "Bearer " + this.authService.getToken
    );
    return this.httpClient.get(this.baseUrl + "/admin/gasstation/wallet/all", {
      headers: headers,
    });
  }

  getGasStationHistory(): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Accept", "application/json");
    headers = headers.append(
      "Authorization",
      "Bearer " + this.authService.getToken
    );
    return this.httpClient.get(this.baseUrl + "/admin/gasstation/history", {
      headers: headers,
    });
  }

  updateGasStationRule(id, bodyObj): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Accept", "application/json");
    let dataobj = bodyObj;
    headers = headers.append(
      "Authorization",
      "Bearer " + this.authService.getToken
    );
    return this.httpClient.post(
      this.baseUrl + "/admin/" + id + "/gasstationrule/update",
      dataobj,
      { headers: headers }
    );
  }

  disableGasStationRule(id, gasstationWalletId): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Accept", "application/json");
    let dataobj = {
      rule: {
        gasstation_walletid: gasstationWalletId,
        // "min_amount":0.2,
        // "transfer_amount":2,
        // "ruleType":"custom",
        // "max_fees":0.2,
        status: 0,
      },
    };
    headers = headers.append(
      "Authorization",
      "Bearer " + this.authService.getToken
    );
    return this.httpClient.post(
      this.baseUrl + "/admin/" + id + "/gasstationrule/update",
      dataobj,
      { headers: headers }
    );
  }

  enableGasStationRule(id, gasstationWalletId): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Accept", "application/json");
    let dataobj = {
      rule: {
        gasstation_walletid: gasstationWalletId,
        status: 1,
      },
    };
    headers = headers.append(
      "Authorization",
      "Bearer " + this.authService.getToken
    );
    return this.httpClient.post(
      this.baseUrl + "/admin/" + id + "/gasstationrule/update",
      dataobj,
      { headers: headers }
    );
  }

  createDefaultRule(data): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append(
      "Authorization",
      "Bearer " + this.authService.getToken
    );
    return this.httpClient.post(
      this.baseUrl + "/admin/gasstationrule/default",
      data,
      { headers: headers }
    );
  }

  // addConsolidationRule(id, bodyObj): Observable<any> {
  //   let headers: HttpHeaders = new HttpHeaders();
  //   headers = headers.append('Accept', 'application/json');
  //   let dataobj = bodyObj;
  //   // console.log(dataobj);

  //   headers = headers.append(
  //     'Authorization',
  //     'Bearer ' + this.authService.getToken
  //   );
  //   return this.httpClient.post(
  //     this.baseUrl + "/wallet/" + id + "/addWalletSettings", dataobj
  //   )
  // }

  // getConsolidationRules(): Observable<any> {

  //   return this.httpClient.get(
  //     this.baseUrl + "/wallet/all/getWalletSettings"
  //   )
  // }

  // removeConsolidationRule(id, asset): Observable<any> {
  //   let dataObj = { "asset": asset };
  //   return this.httpClient.post(
  //     this.baseUrl + "/wallet/" + id + "/removeWalletSettings", dataObj
  //   )
  // }

  appList(): Observable<any> {
    return this.httpClient.get(this.baseUrl + "/apps/list");
  }

  appWalletById(id): Observable<any> {
    return this.httpClient.get(this.baseUrl + "/apps/" + id + "/wallets");
  }

  enableAppWallet(id, body, accessToken): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Authorization", "Bearer " + accessToken);
    return this.httpClient.post(
      this.baseUrl + "/admin/" + id + "/policies/add",
      body,
      { headers: headers }
    );
  }

  approveAppToken(appId, walletId, body): Observable<any> {
    return this.httpClient.post(
      this.baseUrl + "/apps/" + appId + "/wallet/" + walletId + "/approve",
      body
    );
  }

  disableAppAsset(walletId, body): Observable<any> {
    return this.httpClient.post(
      this.baseUrl + "/admin/" + walletId + "/policies/remove",
      body
    );
  }

  view_Notification_Details(): Observable<any> {
    return this.httpClient.get(this.baseUrl + "/admin/getOrgEmails").pipe(
      map((data: string) => {
        return data;
      }),
      catchError((error) => {
        console.log(error);
        return throwError("API data not found!");
      })
    );
  }

  update_Notifications_Details(email: string, type: string): Observable<any> {
    let dataobj = { email: email, type: type };
    return this.httpClient.post(this.baseUrl + "/admin/addOrgEmail", dataobj);
  }

  getWebhook(): Observable<any> {
    return this.httpClient.get(this.baseUrl + "/admin/getWebhook");
  }

  webhook_url(url: string): Observable<any> {
    let dataobj = { webhookUrl: url };
    return this.httpClient.post(this.baseUrl + "/admin/addWebhookUrl", dataobj);
  }

  dev_onBoarding(): Observable<any> {
    return this.httpClient.post(this.baseUrl + "/account/onboarding", null);
  }

  createRefillSchedule(bodyObj): Observable<any> {
    console.info("dataobj", bodyObj);
    return this.httpClient.post(
      this.baseUrl + "/admin/addRefillBatch",
      bodyObj
    );
  }

  add_team(bodyObj): Observable<any> {
    return this.httpClient.post(this.baseUrl + "/teams/create", bodyObj);
  }

  add_team_v2(bodyObj): Observable<any> {
    return this.httpClient.post(this.baseUrl + "/teams/v2/create", bodyObj);
  }

  archiveWallet(bodyObj): Observable<any> {
    console.info("dataobj", bodyObj);
    return this.httpClient.post(this.baseUrl + "/admin/archive", bodyObj);
  }

  getTeamsData(): Observable<any> {
    return this.httpClient.get(
      // this.baseUrl + "/admin/teams/list?pageSize=2&pageNumber=1",
      this.baseUrl + "/teams/list?pageSize=-1"
    );
  }

  editWalletName(bodyObj): Observable<any> {
    console.info("dataobj", bodyObj);
    return this.httpClient.post(this.baseUrl + "/admin/renamewallet", bodyObj);
  }

  createWallet(bodyObj): Observable<any> {
    console.info("dataobj", bodyObj);
    return this.httpClient.post(this.baseUrl + "/wallet/create", bodyObj);
  }

  addMemberAsViewer(bodyObj): Observable<any> {
    console.info("dataobj", bodyObj);
    return this.httpClient.post(
      this.baseUrl + "/admin/addWalletViewer",
      bodyObj
    );
  }

  sendTeamsTxn(walletId, bodyObj, accessToken): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Authorization", "Bearer " + accessToken);
    return this.httpClient.post(
      this.baseUrl + "/wallet/" + walletId + "/createTransaction",
      bodyObj,
      { headers: headers }
    );
  }

  sendGasStationTxn(walletId, bodyObj, accessToken): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Accept", "application/json");
    headers = headers.append("Authorization", "Bearer " + accessToken);
    return this.httpClient.post(
      this.baseUrl + "/admin/gasstation/" + walletId + "/sendtransaction",
      bodyObj,
      { headers: headers }
    );
  }

  getTeamsPendingTxReq(): Observable<any> {
    return this.httpClient.get(this.baseUrl + "/wallet/transactions/pending?reqDevice=web");
  }

  approveInitiatorTx(obj, accessToken): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Authorization", "Bearer " + accessToken);
    return this.httpClient.post(
      this.baseUrl + "/teams/transactions/approve",
      obj,
      { headers: headers }
    );
  }

  approveTeamsTx(obj): Observable<any> {
    return this.httpClient.post(
      this.baseUrl + "/teams/transactions/approve",
      obj
    );
  }

  rejectMpcTx(txId: number, walletId: number): Observable<any> {
    return this.httpClient.post(
      this.baseUrl +
      "/wallet/" +
      walletId +
      "/transactions/" +
      txId +
      "/reject",
      {}
    );
  }

  getAccountStatement(date): Observable<any> {
    return this.httpClient
      .get(
        this.baseUrl +
        "/admin/statement?monthYear=" + date
      )
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }

  getComplianceStatement(date): Observable<any> {
    return this.httpClient
      .get(
        this.baseUrl +
        "/admin/compliance?monthYear=" + date
      )
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      );
  }

  getConfigPolicyApprovals(): Observable<any> {
    return this.httpClient.get(this.baseUrl + "/policy-manager");
  }

  setConfigPolicyApprovals(bodyObj): Observable<any> {
    return this.httpClient.patch(this.baseUrl + "/policy-manager", bodyObj);
  }

  enablingMobileDevice(bodyObj): Observable<any> {
    return this.httpClient.post(
      this.baseUrl + "/account/initiateMPCShare",
      bodyObj
    );
  }

  getAllPendingMessageRequest(): Observable<any> {
    return this.httpClient.get(
      this.baseUrl + "/wallet/messageRequests/pending"
    );
  }

  createMessageSigningRequest(id, body, accessToken): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Authorization", "Bearer " + accessToken);
    return this.httpClient.post(
      this.baseUrl + `/wallet/${id}/createMessageRequest`,
      body,
      { headers: headers }
    );
  }

  getMessageRequestStatus(walletId, identifier): Observable<any> {
    return this.httpClient.get(
      this.baseUrl +
      `/wallet/${walletId}/messageRequests/get?identifier=${identifier}`
    );
  }

  approveMessageSigningRequest(body, accessToken): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Authorization", "Bearer " + accessToken);
    return this.httpClient.post(this.baseUrl + `/teams/message/approve`, body, {
      headers: headers,
    });
  }

  rejectMessageSigningRequest(walletId, messageId): Observable<any> {
    return this.httpClient.post(
      this.baseUrl + `/wallet/${walletId}/messageRequests/${messageId}/reject`,
      {}
    );
  }

  // Travel Rule
  getTravelRulePending(): Observable<any> {
    return this.httpClient.get(
      this.baseUrl + "/travelrules/pending"
    )
  }

  rejectTravelRule(walletId, sequenceId, bodyObj): Observable<any> {
    return this.httpClient.post(
      this.baseUrl + "/travelrules/" + walletId + "/reject/" + sequenceId, bodyObj
    )
  }

  getTravelRuleProvider(): Observable<any> {
    return this.httpClient.get(
      this.baseUrl + "/travelrules/apps/all"
    )
  }

  getTravelRule(): Observable<any> {
    return this.httpClient.get(
      this.baseUrl + "/travelrules"
    )
  }

  updateTravelRuleConfig(bodyObj): Observable<any> {
    return this.httpClient.post(
      this.baseUrl + "/travelrules/configure", bodyObj
    )
  }

  getVasp(query): Observable<any> {
    return this.httpClient.get(
      this.baseUrl + "/travelrules/vasps?q=" + query
    )
  }

  getBeneficiary(address): Observable<any> {
    return this.httpClient.get(
      this.baseUrl + "/travelrules/addresspii/" + address
    )
  }

  txValidate(walletId, body): Observable<any> {
    return this.httpClient.post(
      this.baseUrl + "/travelrules/" + walletId + "/txvalidate", body
    )
  }

  txCreate(walletId, body): Observable<any> {
    return this.httpClient.post(
      this.baseUrl + "/travelrules/" + walletId + "/txcreate", body
    )
  }

  txCreateV2(walletId, body, accessToken): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Accept", "application/json");
    headers = headers.append("Authorization", "Bearer " + accessToken);
    return this.httpClient.post(
      this.baseUrl + "/travelrules/v2/" + walletId + "/txcreate",
      body,
      { headers: headers }
    );
  }

  //

  getAppsField(appId): Observable<any> {
    return this.httpClient.get(
      this.baseUrl + `/apps/${appId}/fields`
    )
  }

  getAppsFieldsData(appId): Observable<any> {
    return this.httpClient.get(
      this.baseUrl + `/apps/${appId}/settings`
    )
  }

  updateAppField(appId, body): Observable<any> {
    return this.httpClient.patch(
      this.baseUrl + `/apps/${appId}/settings`, body
    )
  }

  createAppField(appId, body): Observable<any> {
    return this.httpClient.post(
      this.baseUrl + `/apps/${appId}/settings`, body
    )
  }

  approveMultiSigSendTxnReq(walletId, txnReqId, body): Observable<any> {
    console.log("approveMultiSigSendTxnReq", walletId, txnReqId, body);
    return this.httpClient.post(
      this.baseUrl +
      `/wallet/${walletId}/transaction/${txnReqId}/approveTransaction`,
      body
    );
  }

  rejectMultiSigSendTxnReq(walletId, txnReqId): Observable<any> {
    return this.httpClient.post(
      this.baseUrl +
      `/wallet/${walletId}/transaction/${txnReqId}/rejectTransaction`,
      {}
    );
  }

  // Wallet Screening
  getWalletScreeningProvider(): Observable<any> {
    return this.httpClient.get(
      this.baseUrl + "/wallet-screening/apps/all"
    )
  }

  enableWalletScreeningProvider(appId): Observable<any> {
    return this.httpClient.post(
      this.baseUrl + "/wallet-screening/configure", {
      "appId": appId
    }
    )
  }

  appToggleStatus(appId, status): Observable<any> {
    return this.httpClient.patch(
      this.baseUrl + `/apps/${appId}/toggle-status`, {
      "status": status
    }
    )
  }

  createWalletReq(bodyObj): Observable<any> {
    console.info("dataobj", bodyObj);
    return this.httpClient.post(this.baseUrl + "/wallet/request", bodyObj);
  }

  getWalletReq(orgId): Observable<any> {
    return this.httpClient.get(this.baseUrl + "/wallet/request/" + orgId);
  }

  getTransactionRequest(
    walletId: number,
    txRequestId: number
  ): Observable<{ result: TransactionRequest }> {
    return this.httpClient.get<{ result: TransactionRequest }>(
      `${this.baseUrl}/wallet/${walletId}/txrequestid/${txRequestId}`
    );
  }

  updateRiskDisclosureStatus(payload) {
    return this.httpClient.patch(`${this.baseUrl}/account/risk_disclosure`, payload);
  }
  
  verifySumsub(): Observable<any> {
    return this.httpClient.get(this.baseUrl + "/users/kyc/link/user");
  }

  getFaceAuthToken(identifier: string){
    return this.httpClient.get(this.baseUrl + `/users/kyc/transaction/face-auth-token?id=${identifier}`);
  }

  updateOrgUserRole(orgId: number, orgUserId: number, userRole: UserRoleEnum, accessToken: any): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Authorization", "Bearer " + accessToken);
    const body = { orgId, orgUserId, userRole };
    return this.httpClient.patch(`${this.baseUrl}/account/updateUserRole`, body, { headers });
  }

  createSpeedUpTxnPreBuild(walletId: number, sequenceId: number , payload: Object = {}){
    return this.httpClient.post(`${this.baseUrl}/wallet/${walletId}/generatePrebuildForResendTx`, {
      sequenceId,
      ...payload
    });
  }

  getCurrentMarketfees(chain: string){
    return this.httpClient.get(this.baseUrl + "/public/fees/" + chain);
  }

  getRbfTxnData(txnIds: Array<number>){
    if (txnIds.length === 0) {
      // Handle the case where no txnIds are provided
      return throwError("No transaction IDs provided");
    }
    const queryParams = txnIds.map(id => `id=${id}`).join('&');
    const url = `${this.baseUrl}/wallet/tx/rbf?${queryParams}`;
    return this.httpClient.get(url);
  }

  addNewMultisigRbfTransaction(
    walletId: string,
    txreq: any
  ): Observable<any> {
    console.info("Transaction Request:", txreq);
    return this.httpClient
      .post(this.baseUrl + "/wallet/" + walletId + "/reSendTransaction", {
        ...txreq
      })
  }
  
  /**
   * Retrieves audit logs based on the specified parameters.
   * 
   * @param pageNumber - The page number of the audit logs.
   * @param pageSize - The number of audit logs to retrieve per page.
   * @param orgId - The ID of the organization.
   * @param searchKey - The search key to filter audit logs.
   * @param action - The action performed in the audit logs.
   * @param user - The user associated with the audit logs.
   * @param fromDate - The starting date to filter audit logs.
   * @param toDate - The ending date to filter audit logs.
   * @returns An observable that emits the retrieved audit logs.
   */
  getAuditLogs(pageNumber,pageSize,orgId,searchKey,action,user,fromDate,toDate):Observable<any> {
    let url = constructUrl(pageNumber,pageSize,orgId,searchKey,action,user,fromDate,toDate);
    return this.httpClient.get(url);
  }

  getAuditFilters(orgId):Observable<any> {
    return this.httpClient.get(`${this.baseUrl}/org/orgId/${orgId}/filters`);
  }
  
  getWebhookCalls(url: string):Observable<any> {
    return this.httpClient.get(this.baseUrl + url);
  }

  resendWebhooks(orgId,body): Observable<any> {
    const url = `${this.baseUrl}/tx-webhook/org/${orgId}/resend`;
    return this.httpClient.post(url, body);
  }
}
