import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { DataService } from './shared/services/data.service';
import { LoginComponentComponent } from './components/login-component/login-component.component';
import { AuthServiceJWT } from './shared/services/auth.service';
import { WalletserviceService } from './shared/services/walletservice.service';
import { HttpService } from './shared/services/http.service';
import { MembersService } from './shared/services/members.service'
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input'
import { AuthModule } from '@auth0/auth0-angular';
import { MomentModule } from 'ngx-moment';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { AdminPanelComponent } from './components/admin-panel/admin-panel.component';
import { MultisigjourneyComponent } from './components/multisigjourney/multisigjourney.component';
import { SegmentModule } from 'ngx-segment-analytics';
import { PolicyService } from '../app/shared/services/policy.service';
import { TagifyModule } from 'ngx-tagify';
import { GetStartedComponent } from './components/get-started/get-started.component';


import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SignupComponent } from './components/signup/signup.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { ErrorPageComponent } from './shared/components/error-page/error-page.component'
import { TokenInterceptor } from './shared/services/token.interceptor';
import { ResponseInterceptor } from './shared/services/response.interceptor';
import { GlobalErrorHandler } from './shared/services/error-handler';
import { ParallelRequestInterceptor } from './shared/services/parallel-request.interceptor';
import { ApisignerComponent } from './components/dev/apisgner/apisigner.component';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { EventService } from './shared/services/events';
import { ReportsService } from './shared/services/reports.service';
import { ReportsHttpService } from './shared/services/reports-http.service';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { SidebarComponent } from './shared/components/sidebar/sidebar.component';
import { ContentComponent } from './shared/components/layout/content/content.component';
import { TapToTopComponent } from './shared/components/tap-to-top/tap-to-top.component';
import { FrozenPendingTooltipBalanceComponent } from './components/wallet-details/frozen-pending-tooltip-balance/frozen-pending-tooltip-balance.component';
import { AuditLogsComponent } from './components/reports/audit-logs/audit-logs/audit-logs.component';
import { LogFiltersComponent } from './components/reports/audit-logs/log-filters/log-filters.component';
import { EventmodalComponent } from './components/reports/eventmodal/eventmodal.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { UserGuidingService } from './shared/services/user-guiding.service';

declare global {
  interface Window { analytics: any; ethereum: any; }
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    TapToTopComponent,
    LoginComponentComponent,
    AdminPanelComponent,
    MultisigjourneyComponent,
    GetStartedComponent,
    VerifyEmailComponent,
    SignupComponent,
    ErrorPageComponent,
    ApisignerComponent,
    AccessDeniedComponent,
    FrozenPendingTooltipBalanceComponent,
    AuditLogsComponent,
    LogFiltersComponent,
    EventmodalComponent
  ],
  imports: [
    MatInputModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    ClipboardModule,
    Ng2SearchPipeModule,
    TagifyModule.forRoot(),
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        'm': 59
      }
    }),
    SegmentModule.forRoot({ apiKey: environment.writekey_segment, debug: true, loadOnInitialization: true }),
    // for HttpClient use:
    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule,
    AuthModule.forRoot({
      domain: environment.auth0Domain,
      clientId: environment.auth0ClientKey,
      // useRefreshTokens: true,
      cacheLocation: 'localstorage'
    }),
    NgxDaterangepickerMd.forRoot(),
    NgApexchartsModule,
    NgxSkeletonLoaderModule.forRoot({
      theme: {
        animation: 'progress-dark',
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    {
      // processes all errors
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ParallelRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    DataService,
    AuthServiceJWT,
    WalletserviceService,
    HttpService,
    MembersService,
    PolicyService,
    EventService,
    ReportsService,
    ReportsHttpService,
    UserGuidingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }