import { Component, OnInit } from "@angular/core";
import { AuthServiceJWT } from "../../shared/services/auth.service";
import { AuthService } from "@auth0/auth0-angular";
import { SegmentService } from "ngx-segment-analytics";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
@Component({
  selector: "app-login-component",
  templateUrl: "./login-component.component.html",
  styleUrls: ["./login-component.component.scss"],
})
export class LoginComponentComponent implements OnInit {
  environment = environment;
  buildType = environment.buildType;
  constructor(
    public afAuth: AuthService,
    public authService: AuthServiceJWT,
    private segment: SegmentService,
    private router: Router
  ) {
    this.afAuth.isAuthenticated$.subscribe((isAuthenticated) => {
      if (!isAuthenticated) {
        this.segment.track("logout-init").catch((err) => {});
        this.authService.loginWithRedirect("login", "");
      } else {
        this.router.navigateByUrl("/");
      }
    });
  }
  ngOnInit() {}
}