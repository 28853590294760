export enum MinimumRequiredInitiators {
  MIN_INITIATORS_REQUIRED_CUSTODY = 2,
  MIN_INITIATORS_REQUIRED_NON_CUSTODY = 1,
}

export enum ArchivedStatus {
  ARCHIVED = 1,
  NOT_ARCHIVED = 0,
}

export enum WalletMessgaes {
  ARCHIVED_POLICY = 'Add policy is disabled for Archived Wallet',
  ARCHIVED_MEMBER = 'Add member is disabled for Archived Wallet',
}

export const WALLETS_HARDWARES = ['trezor', 'ledger'];
