import { Component, Input, OnInit } from '@angular/core';
import { AccessControlService } from '../../shared/services/access-control.service';

@Component({
  selector: 'app-wallet-create-selector',
  templateUrl: './wallet-create-selector.component.html',
  styleUrls: ['./wallet-create-selector.component.scss']
})
export class WalletCreateSelectorComponent implements OnInit {

  @Input() orgProfile: any; 
  @Input() component_for: any;

 
  constructor(private accessControlService: AccessControlService) { }

  ngOnInit(): void {
  }

  get isCustodyOrg() {
    return this.accessControlService.getIsCustodyOrg();
  }

}
