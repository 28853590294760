import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { orgType } from './shared/entities/members';
import { DataService } from './shared/services/data.service';
import { HttpService } from './shared/services/http.service';
import { environment } from 'src/environments/environment';
import * as OrgHelper from './shared/helpers/org.utils';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrgAuthGuard implements CanActivate {
  blackListRouteCustody = [
    '/apps',
    '/nft-wallet',
    '/wallets/staking',
    '/wallets/staking_legacy',
    '/wallets/refills',
    '/wallets/staking/calculator',
    '/dev/gas-station',
    '/dev/consolidation',
    '/settings/wallet-key-card'
  ]
  blackListRouteNonCustody = [
    '/apps',
    '/nft-wallet',
    '/wallets/staking',
    '/wallets/staking_legacy',
    '/wallets/refills',
    '/wallets/staking/calculator',
    '/settings/wallet-key-card'
  ]
  exchanges = [
    '/wallets/exchanges',
  ];

  orgType = orgType
  constructor(private router: Router, private data: DataService, private httpService: HttpService) {
  }
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.httpService.getprofile().pipe(
      switchMap(profile => {
        const organization = profile['organizations'][0];

        // Determine if the organization is a custody or non-custody organization
        const isCustodyOrg = OrgHelper.isCustodyOrg(organization);
        const isNonCustodySubOrg = OrgHelper.isNonCustodySubOrg(organization);
        const isPathBlackListedCustody = this.blackListRouteCustody.includes(window.location.pathname);
        const isPathBlackListedNonCustody = this.blackListRouteNonCustody.includes(window.location.pathname);

        if (isCustodyOrg && !OrgHelper.isSdkEnabledForCustodyOrg(organization)) {
          this.blackListRouteCustody.push('/dev/configure');
        }

        if (state.url.includes(this.exchanges[0])) {
          this.exchanges.push(state.url);
        }

        if (isPathBlackListedCustody && isCustodyOrg) {
          this.router.navigate(['/']);
          return of(false);
        } else if (isPathBlackListedNonCustody && isNonCustodySubOrg) {
          this.router.navigate(['/']);
          return of(false);
        } else if (profile.organizations[0].orgType === orgType.management) {
          window.location.href = environment.adminPanelUrl;
          return of(false);
        } else if (this.exchanges.includes(window.location.pathname) && profile['organizations'][0].orgType !== orgType.sub && !profile['organizations'][0].exchange) {
          this.router.navigate(['/']);
          return of(false);
        } else {
          return of(true);
        }
      }),
      catchError((error) => {
        return of(false);
      })
    );
  }
}