import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { TransactionRequest } from "../entities/TransactionRequest";
import { MpcTxData, MpcTxRequest } from "../entities/mpcTxRequest";
import {
  GasstationRule,
  Wallet,
  getStakingWalletList,
} from "../entities/wallet";
import { Transaction } from "../entities/Transaction";
import { generate_api } from "../entities/User";
import {
  pendingPolicyRequest,
  pendingUserRequest,
  getUserProfile,
  Policy,
} from "../entities/Policy";
import { StakingV2 } from "../entities/StakingV2";
import { WalletRequest } from "../entities/walletRequest";
import { LocalForageService } from "./localforage.service";
import { HttpService } from "./http.service";
import { IExchangeData } from "../constants/exchange.const";

@Injectable()
export class DataService {
  private pageSource = new BehaviorSubject("settings");
  currentPage = this.pageSource.asObservable();

  changePage(page: string) {
    this.pageSource.next(page);
  }

  private messageSource = new BehaviorSubject("default");
  currentMessage = this.messageSource.asObservable();
  allScheduleTransactionRequests: BehaviorSubject<TransactionRequest[]> =
    new BehaviorSubject<TransactionRequest[]>(null);
  allSchediletxs = this.allScheduleTransactionRequests.asObservable();
  txRequest: BehaviorSubject<TransactionRequest> =
    new BehaviorSubject<TransactionRequest>(null);
  txMpcRequest: BehaviorSubject<MpcTxData> = new BehaviorSubject<MpcTxData>(
    null
  );
  txMessageSignRequest: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  txRequest_mobile: BehaviorSubject<TransactionRequest> =
    new BehaviorSubject<TransactionRequest>(null);
  pendingMessageSigningRequest: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  pendingTxRequest: BehaviorSubject<TransactionRequest[]> = new BehaviorSubject<
    TransactionRequest[]
  >(null);
  pendingMpcRequest: BehaviorSubject<MpcTxData[]> = new BehaviorSubject<
    MpcTxData[]
  >(null);
  pendingTravelRuleTxRequest: BehaviorSubject<TransactionRequest[]> =
    new BehaviorSubject<TransactionRequest[]>(null);
  walletRequest: BehaviorSubject<WalletRequest[]> = new BehaviorSubject<
    WalletRequest[]
  >(null);
  wallets: BehaviorSubject<Wallet[]> = new BehaviorSubject<Wallet[]>(null);
  triggerRefresh: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  scheduledTxRequest: BehaviorSubject<TransactionRequest[]> =
    new BehaviorSubject<TransactionRequest[]>(null);
  txs: BehaviorSubject<Transaction[]> = new BehaviorSubject<Transaction[]>(
    null
  );
  pendingPolicyRequest: BehaviorSubject<pendingPolicyRequest[]> =
    new BehaviorSubject<pendingPolicyRequest[]>(null);
  pendingUserRequest: BehaviorSubject<pendingUserRequest[]> =
    new BehaviorSubject<pendingUserRequest[]>(null);
  getUserProfile: BehaviorSubject<getUserProfile> =
    new BehaviorSubject<getUserProfile>(null);
  getStakingWalletList: BehaviorSubject<getStakingWalletList[]> =
    new BehaviorSubject<getStakingWalletList[]>(null);
  getStakingV2WalletList: BehaviorSubject<StakingV2> =
    new BehaviorSubject<StakingV2>(null);
  generate_api: BehaviorSubject<generate_api[]> = new BehaviorSubject<
    generate_api[]
  >(null);
  isKycComplete: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  gasStationRules: BehaviorSubject<GasstationRule[]> = new BehaviorSubject<
    GasstationRule[]
  >(null);
  refillTxs: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  exchangeAccountsSubject: BehaviorSubject<IExchangeData[]> = new BehaviorSubject<IExchangeData[]>(null);
  coinList:BehaviorSubject<any> = new BehaviorSubject<any>([]);
  exchangeAccountStatus:Subject<boolean> = new Subject<boolean>();
  constructor(private localForage: LocalForageService, private httpService: HttpService) {
    localForage.getItem("wallets").then((data: Wallet[]) => {
      if (data) {
        this.wallets.next(data);
      } else {
        this.wallets.next([]);
      }
    });

    localForage.getItem("exchanges").then((data: any) => {
      if (data) {
        this.exchangeAccountsSubject.next(data);
      } else {
        this.exchangeAccountsSubject.next([]);
      }
    });
  }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  newGasstaionRules(message: GasstationRule[]) {
    this.gasStationRules.next(message);
  }

  newTxRequest(txRequestNew: TransactionRequest) {
    this.txRequest.next(txRequestNew);
  }

  newMpcTxRequest(txRequestNew: MpcTxData) {
    this.txMpcRequest.next(txRequestNew);
  }

  newMessageSignRequest(txRequestNew: MpcTxData) {
    this.txMessageSignRequest.next(txRequestNew);
  }

  newTxRequest_mobile(txRequestNew: TransactionRequest) {
    this.txRequest_mobile.next(txRequestNew);
  }

  changePendingTxRequest(txRequests: TransactionRequest[]) {
    if (txRequests && this.pendingTxRequest.value == txRequests) {
      return;
    }
    this.pendingTxRequest.next(txRequests);
  }

  changePendingTravelRuleTxRequest(txRequests: TransactionRequest[]) {
    if (txRequests && this.pendingTravelRuleTxRequest.value == txRequests) {
      return;
    }
    this.pendingTravelRuleTxRequest.next(txRequests);
  }

  changePendingMessageSigningRequest(txRequests: any) {
    if (txRequests && this.pendingMessageSigningRequest.value == txRequests) {
      return;
    }
    this.pendingMessageSigningRequest.next(txRequests);
  }

  changeMpcPendingTxRequest(txRequests: MpcTxData[]) {
    if (txRequests && this.pendingMpcRequest.value == txRequests) {
      return;
    }
    this.pendingMpcRequest.next(txRequests);
  }

  changeWalletRequest(req: WalletRequest[]) {
    if (req && this.walletRequest.value == req) {
      return;
    }
    this.walletRequest.next(req);
  }

  changePendingPolicyRequest(txRequests: pendingPolicyRequest[]) {
    if (txRequests && this.pendingPolicyRequest.value == txRequests) {
      return;
    }
    this.pendingPolicyRequest.next(txRequests);
  }

  changependingUserRequest(pendingRequests: pendingUserRequest[]) {
    this.pendingUserRequest.next(pendingRequests);
  }

  changeUserProfileRequest(userProfile: getUserProfile) {
    this.getUserProfile.next(userProfile);
  }

  changeStakingWalletRequest(walletRequests: getStakingWalletList[]) {
    this.getStakingWalletList.next(walletRequests);
  }

  changeStakingV2WalletRequest(walletRequests: StakingV2) {
    this.getStakingV2WalletList.next(walletRequests);
  }

  changeScheduledTxRequest(txRequests: TransactionRequest[]) {
    this.scheduledTxRequest.next(txRequests);
  }

  changeTxs(txs: Transaction[]) {
    this.txs.next(txs);
  }

  changeWallets(wallets: Wallet[]) {
    if (wallets && this.wallets.value == wallets) {
      return;
    }
    // Exclude exchange wallets from wallet list
    wallets = wallets.filter((wallet) => {
      return wallet.type !== "exchange"; 
    });
    this.localForage.setItem("wallets", wallets);
    this.wallets.next(wallets);
  }

  nextAllScheduleTransactionRequests(
    allScheduleTransactionRequests: TransactionRequest[]
  ) {
    this.allScheduleTransactionRequests.next(allScheduleTransactionRequests);
  }

  get_api_details(generated_api: generate_api[]) {
    this.generate_api.next(generated_api);
  }

  changeKycUpdateStatus(flag: boolean) {
    this.isKycComplete.next(flag);
  }

  changeExchangeAccounts(accounts: any) {
    this.localForage.setItem(`exchanges`, accounts).then(() => {
      this.exchangeAccountsSubject.next(accounts);
    });
  }
  /**
   * Updates the rescan coin list.
   * 
   * @param coinList - The new coin list.
   */
  changeRescanCoinList(coinList: []) {
    this.coinList.next(coinList);
  }

  /**
   * check account status and load list
   */
  checkExchangeAccountStatus(value:boolean) {
    this.exchangeAccountStatus.next(value);
  } 
}
