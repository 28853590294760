import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpEventType,
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject, throwError } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ParallelRequestInterceptor implements HttpInterceptor {

  private activeCalls: Map<string, Subject<any>> = new Map();

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method !== "GET") return next.handle(request);

    if (this.activeCalls.has(request.url)) {
      console.log("active call returned", request.url);
      const subject = this.activeCalls.get(request.url);
      return subject.isStopped ? subject!.asObservable() : next.handle(request);
    }

    this.activeCalls.set(request.url, new Subject<any>());
    return next.handle(request)
      .pipe(
        catchError(error => {
          this.activeCalls.delete(request.url);
          return throwError(error);
        }),
        filter(res => res.type === HttpEventType.Response),
        tap(res => {
          const subject = this.activeCalls.get(request.url);
          subject!.next(res);
          subject!.complete();
          this.activeCalls.delete(request.url);
        })
      )
  }
}