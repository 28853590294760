<div class="d-inline">
    <ng-container *ngIf="TeamSigningSubType.MPC === selectedSigningSubType">
        <label class="text-muted font-weight-500">Choose mobile if you want to</label>
        <div class="mt-1 text-dark">
            <ul class="default-bullet-list">
                <li class="mb-1">Sign transactions seamlessly on the go with your iPhone or Android device.</li>
                <li class="mb-1">Leverage our cutting-edge MPC technology.</li>
                <li>Sign/Approve wallet transactions “frequently”.</li>
            </ul>
    
        </div>
    </ng-container>
    
    <ng-container *ngIf="TeamSigningSubType.MULTISIG === selectedSigningSubType">
        <label class="text-muted font-weight-500">Notes</label>
        <div class="mt-1 text-dark" *ngIf="showNote">
            <ul class="default-bullet-list">
                <li class="mb-1">Sign using Trezor or Ledger hardware wallets.</li>
                <li class="mb-1">Wallets will use multi-sig technology.</li>
                <li>Best suited if transaction frequency is less but volume is high.</li>
            </ul>
        </div>
    </ng-container>
</div>